@import "variables/colour";
@import "mixins/layout";

// ------------------------------------------------------------------
//   Variables
// ------------------------------------------------------------------
$sub-nav-is-active-background: $grey15 !default;

// ------------------------------------------------------------------
//   Sub navigation
// ------------------------------------------------------------------
.sub-nav {
    @include columns(3);
    display: none;
    @include mq(tablet-land) {
        display: block;
        border-right: 1px solid $grey40;
        padding-top: $space-l;
        // General style
        ul {
            margin: 0;
            li {
                list-style: none;
                margin: 0;
                a {
                    border-bottom: none;
                    color: $grey-dark;
                    text-decoration: none;
                    display: block;
                    line-height: 1.5;
                    padding: 8px;
                    &:hover,
                    &:focus {
                        color: $list-link-colour-hover;
                        background: $list-link-background-colour-hover;
                    }
                    &:active {
                        color: $list-link-colour-focus;
                        background: $list-link-background-colour-focus;
                    }
                }
            }
        }
        > ul {
            padding: 0;
            margin: 0;
            font-weight: $medium;
            font-size: rem(14px);
            // Current, parent and ancestor
            > .is-active {
                // Current page
                > a {
                    font-weight: $bold;
                    &:hover,
                    &:focus {
                        color: $list-link-colour-focus;
                        background: $list-link-background-colour-focus;
                    }
                }
            }
            .is-active-ancestor {
                // Current page ancestor
                > a {
                    font-weight: $bold;
                }
            }
            li.is-active> ul,
            li.is-active-ancestor> ul,
            li.is-active-parent> ul {
                // Display the next sub level (level 2 and 3)
                display: block;
            }
            // Level 1
            > li> a {
                color: $list-link-colour;
                font-weight: 500;
                display: block;
                padding: $space-s 6px; //.6em;
                text-transform: uppercase;
                font-size: rem(14px);
            }
            // Level 2
            ul {
                border-left: 1px solid $grey40;
                margin-left: 6px;
                a {
                    padding: 6px 8px;
                }
                > .is-active {
                    // Current page
                    > a {
                        background: $sub-nav-is-active-background;
                        border-left: 1px solid $primary-colour;
                        font-weight: $bold;
                        padding-left: rem(8px);
                        &:hover,
                        &:focus {
                            color: $list-link-colour-focus;
                            background: $list-link-background-colour-focus;
                        }
                        &:active {
                            border-left: 1px solid $list-link-background-colour-focus;
                        }
                    }
                }
                // Level 3
                ul {
                    display: none;
                    border-left: none;
                    margin-left: 0;
                    font-weight: $medium;
                    li {
                        margin-left: rem(8px);
                    }
                    > .is-active {
                        // Active status
                        > a {
                            font-weight: $medium;
                            &:hover,
                            &:focus {
                                color: $list-link-colour-focus;
                                background: $list-link-background-colour-focus;
                            }
                        }
                    }
                    a {
                        padding: 6px 8px;
                    }
                }
            }
        }
    }
}

// ------------------------------------------------------------------
//   Sub navigation toggle
//   Only show when the screen size is under table landscape
// ------------------------------------------------------------------
.toggle-icon--sub-navi {
   // @extend .button;
    position: absolute;
    display: block;
    margin: 0; // Remove margin bottom from the default button style
    text-align: center;
    width: auto;
    color: $black70 !important;
    padding: rem(4px $space-s);
    @include mq(tablet-port) {}
    @include mq(tablet-land) {
        display: none;
    }
    // Toggle icon
    i {
        line-height: normal !important;
        font-size: rem(30px);
    }
    &.is-active {
        // If the toggle is active, then apply the dark button style
        background-color: $black80;
        border: 1px solid $black; // Overwrite link style
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 1);
        color: $white;
        height: 100%;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .13);
        background-image: linear-gradient(to top, $black 0%, #191919 49.76%, #252525 53.76%, #333 100%) !important;
        border-bottom-left-radius: 0; // Remove bottom left radius
        border-bottom-right-radius: 0; // Remove bottom right radius
    }
}