@import "variables/font";
@import "functions/units";
@import "mixins/layout";
@import "mixins/text";
@import "elements/typography";

.subsite-title {
    @extend .heading;
    display: inline-block;
    text-transform: uppercase;
    @include adjust-leading-to(0.9);
    font-size: rem(19px);
    padding: rem(8px 0 8px $space-m);

    @include mq(tablet-port) {
        font-size: rem(20px);
    }

    @include mq(tablet-land) {
        font-size: rem(24px);
    }
}