@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/colour";
@import "functions/units";
@import "mixins/border";
@import "mixins/images";
@import "mixins/links";
@import "mixins/lists";
@import "mixins/layout";
@import "mixins/text";

// ------------------------------------------------------------------
//   Events list
// ------------------------------------------------------------------
.event-list {
    border-bottom: none;
    background-color: $white;
    @include no-bullets;

    li.event-list__item{
        margin: 0;
    }
    // ------------------------------------------------------------------
    //   Events list elements
    // ------------------------------------------------------------------
    &{
        &__item {
            @include clearfix;
            border-bottom: 1px solid $grey60;
            margin: 0;
            padding-bottom: $space-m;
            &:last-child {
                border-bottom: none;
            }
        }
        &__item__head__meta {
            @include clearfix;
            border-bottom: 1px solid $grey50;
            background-color: $black5;
            overflow: hidden; // For full height columns
            box-shadow: inset 0 0 3px $white;
            .dtstart,
            .multiple-dates {
                display: block;
                float: left;
                padding-top: $space-s;
                padding-bottom: $space-s;
            }
            .multiple-dates {
                width: 45%;
                padding-left: $space-m;
                padding-right: $space-m;
                font-size: rem(15px);
                @include border-shaded(left);
                @include mq(tablet-port) {
                    width: 20%;
                }
                @include mq(tablet-land) {
                    width: 50%;
                }
                @include mq(wide-screen) {
                    width: 40%;
                }
                i {
                    position: relative;
                    top: 2px; // Fix positioning
                    margin-left: 0.5em;
                    color: $olive;
                }
                a {
                    @include link-colour($border: false);
                }
            }
            .dtstart {
                @include clearfix;
                width: 55%;
                font-size: rem(15px);
                padding-left: $space-m;
                padding-right: $space-m;
                @include mq(tablet-port) {
                    width: 80%;
                }
                @include mq(tablet-land) {
                    width: 50%;
                }
                @include mq(wide-screen) {
                    width: 60%;
                }
                &.no-multiple-dates {
                    width: 100%;
                }
            }
        }
        &__item__head__title {
            font-size: rem(18px);
            @include adjust-leading-to(0.8);
            margin: $space-s $space-m;
            @include mq(tablet-small) {
                font-weight: normal;
            }
            a {
                @include link-colour( $border: false);
            }
        }
        &__item__thumbnail {
            width: $thumbnail-small;
            float: right;
            margin: 0 $space-m;
            img {
                display: block;
                border: 1px solid $image-border-colour;
                width: 100%;
            }
            a {
                @include image-clickable; // Hover status
            }
        }
        &__item__content {
            @include clearfix;
            margin-top: 0;
            margin: 0 $space-m;
            p {
                margin-bottom: 0;
                font-size: rem(15px);
                @include adjust-leading-to(0.85);
            }
        }        
    }

    // ------------------------------------------------------------------
    //   Events list - condensed
    // ------------------------------------------------------------------
    &--condensed {
        @include no-bullets;

        &__item {
            @include clearfix;
            border-top: 1px solid $white;
            border-bottom: 1px solid $grey40;
            margin: 0;
            padding: $space-s $space-m;
            &:last-child {
                border-bottom: none;
            }
            // Secondary content column
            .secondary-content & {
                @include mq(tablet-land) {
                    padding: $space-s;
                }
            }
        }

        &__item__head__title {
            font-family: $base-font-family;
            text-transform: none;
            font-weight: normal;
            @include adjust-leading-to(0.8);
            a {
                @include link-colour( $border: false);
            }
            font-size: rem(18px);
            @include mq(tablet-land) {
                font-size: rem(17px);
            }
            // Secondary content
            .secondary-content & {
                font-size: rem(17px);
                @include mq(tablet-land) {
                    font-size: rem(15px);
                    margin-bottom: $space-xs;
                }
            }
        }
        .dtstart {
            @include clearfix;
            @extend .icon-calendar;
            display: block;
            background: {
                repeat: no-repeat;
                position: left 4px;
                size: 16px 16px;
            }
            padding-left: rem(20px);
            padding-right: $space-s;
            font-size: rem(15px);
            @include mq(tablet-land) {
                font-size: rem(14px);
            }
        }
        .multiple-dates {
            padding-left: $space-m;
            padding-right: $space-m;
            font-size: rem(15px);
            i {
                position: relative;
                top: 2px; // Fix positioning
                margin-left: 0.5em;
                color: $olive;
            }
            a {
                @include link-colour($border: false);
            }
        }
    }
    .secondary-content .event-list__item__thumbnail {
        width: $thumbnail-small;
    }    
}
