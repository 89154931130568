@import "variables/layout";
@import "functions/layout";
@import "mixins/layout";

.centre-content{
    @include container;
    @include mq(tablet-land) {
        float: none;
        margin: 0 auto;

        @for $i from 1 through $total-columns {
            &.col-#{$i}{
                float: none;
                margin: 0 auto;
            }
        }
    }
}
