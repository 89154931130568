@import "variables/button";
@import "variables/input";
@import "templates/action-input";

@mixin placeholder-colour($colour) {
    &.placeholder{
        color: $colour;
    }

    &::placeholder{
        color: $colour;
    }
}

@mixin action-input(
    $colour: $action-input-colour,
    $background-colour: $action-input-background-colour,
    $background-image: $action-input-background-image,
    $border-colour: $action-input-border-colour,
    $box-shadow: $action-input-box-shadow,
    $text-shadow: $action-input-text-shadow,

    $colour-hover: $action-input-colour-hover,
    $background-colour-hover: $action-input-background-colour-hover,
    $background-image-hover: $action-input-background-image-hover,
    $border-colour-hover: $action-input-border-colour-hover,
    $box-shadow-hover: $action-input-box-shadow-hover,

    $colour-active: $action-input-colour-active,
    $background-colour-active: $action-input-background-colour-active,
    $background-image-active: $action-input-background-image-active,
    $border-colour-active: $action-input-border-colour-active,
    $box-shadow-active: $action-input-box-shadow-active,

    $template: $action-input-template
) {
    @extend #{$template};

    @include colours(
        $colour: $colour,
        $background-colour: $background-colour,
        $background-image: $background-image,
        $border-colour: $border-colour,
        $box-shadow: $box-shadow,
        $text-shadow: $text-shadow,

        $colour-hover: $colour-hover,
        $background-colour-hover: $background-colour-hover,
        $background-image-hover: $background-image-hover,
        $border-colour-hover: $border-colour-hover,
        $box-shadow-hover: $box-shadow-hover,

        $colour-active: $colour-active,
        $background-colour-active: $background-colour-active,
        $background-image-active: $background-image-active,
        $border-colour-active: $border-colour-active,
        $box-shadow-active: $box-shadow-active
    );
}

//TODO (MS-2016/10/25): Find a better name and location
@mixin colours(
    $colour: $button-colour--dark,
    $background-colour: $button-background-colour--dark,
    $background-image: $button-background-image--dark,
    $border-colour: $button-border-colour--dark,
    $box-shadow: $button-box-shadow--dark,
    $text-shadow: $button-text-shadow--dark,

    $colour-hover: $button-colour-hover--dark,
    $background-colour-hover: $button-background-colour-hover--dark,
    $background-image-hover: $button-background-image-hover--dark,
    $border-colour-hover: $button-border-colour-hover--dark,
    $box-shadow-hover: $button-box-shadow-hover--dark,

    $colour-active: $button-colour-active--dark,
    $background-colour-active: $button-background-colour-active--dark,
    $background-image-active: $button-background-image-active--dark,
    $border-colour-active: $button-border-colour-active--dark,
    $box-shadow-active: $button-box-shadow-active--dark
) {
    &,
    &:visited{
        color: $colour;
        background-color: $background-colour;
        background-image: $background-image;
        border: 1px solid $border-colour;
        box-shadow: $box-shadow;
        text-shadow: $text-shadow;
    }
    &:hover,
    &:focus {
        // Hover and focus status
        color: $colour-hover;
        background-color: $background-colour-hover;
        background-image: $background-image-hover;
        border-color: $border-colour-hover;
        box-shadow: $box-shadow-hover;
    }
    &:active {
        // Active status
        color: $colour-active;
        background-color: $background-colour-active;
        background-image: $background-image-active;
        border-color: $border-colour-active;
        box-shadow: $box-shadow-active;
    }    
}