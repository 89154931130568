@import "variables/layout";
@import "functions/units";
@import "mixins/layout";
@import "mixins/links";
@import "mixins/icons";

.breadcrumb {
    display: none;
    position: relative;
    padding: $space-m 0 0;
    @include mq(tablet-land) {
        display: block;
        float: left;
        width: 74.5%;
        padding: rem(29px 0 0);
    }

    &--item {
        display: inline-block;
        font-size: rem(12px);
        &:before {
            @include icon("arrow-right", $position: null);
            display: inline-block;
            margin-top: -3px;
            margin-left: rem(7px);
            margin-right: $space-xs;
            font-size: rem(7px);
        }
        &:first-child:before {
            margin-right: 0;
            margin-left: 0;
        }
        // Link colours
        a {
            @include link-colour($border: false);
        }
    }    
}