@import "variables/colour";
@import "functions/layout";
@import "mixins/layout";
@import "mixins/icons";

// Image slider navigators
.slider {
    position: relative;

    // Slider pagination
    &__pagination {
        display: none;
        @include mq(tablet-land) {
            background-color: $white;
            display: block;
            width: 30%;
            position: absolute;
            bottom: 0;
            right: 0;
            padding-top: rhythm(1);
            z-index: 100;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }    
}

.slider-nav--next,
.slider-nav--prev {
    display: block;
    position: absolute;
    top: 22%;
    height: 3em;
    width: 2.5em;
    background: $white;
    margin-top: -1.5em;
    @include mq(tablet-small) {
        top: 30%;
    }
    @include mq(tablet-port) {
        top: 34%;
    }
    @include mq(tablet-land) {
        top: 34%;
    }
    &:after {
        color: $black85;
    }
    &:before {
        color: $white;
    }
    &:hover {
        cursor: pointer;
    }
}

.slider-nav--prev {
    left: 0;
    &:before {
        @include arrow( $wide: true, $direction: left, $position: absolute);
        left: 50%;
        margin-left: -5px;
        margin-top: -7px;
    }
    &:after {
        @include arrow( $wide: true, $direction: left, $position: absolute);
        left: 50%;
        margin-left: -5px;
    }
}

.slider-nav--next {
    right: 0;
    &:before {
        @include arrow( $wide: true, $position: absolute);
        left: 50%;
        margin-left: -3px;
        margin-top: -7px;
    }
    &:after {
        @include arrow( $wide: true, $position: absolute);
        left: 50%;
        margin-left: -3px;
    }
}
