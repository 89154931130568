@import "variables/message";
@import "mixins/form-validation";

// ------------------------------------------------------------------
//   Validation styles
// ------------------------------------------------------------------
.form{
    @each $message-type, $message-style in $message-types{
        &-#{$message-type}{
            @include form-validation($message-style...);
        }
    }
}

// ------------------------------------------------------------------
//   Definition list validation styling fixes
// ------------------------------------------------------------------
%form-validation-fieldset-highlight--dd{
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
}
%form-validation-fieldset-highlight--dt{
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
}

dl{
    @each $message-type, $message-style in $message-types{
        dd.form-#{$message-type}{
            &--highlight {
                @extend %form-validation-fieldset-highlight--dd;
            }
        }
        dt.form-#{$message-type}{
            &--highlight {
                @extend %form-validation-fieldset-highlight--dt;
            }
        }
    }
}