@import "variables/colour";
@import "variables/icon";
@import "functions/units";
@import "mixins/layout";
@import "mixins/lists";
@import "mixins/icons";
@import "virtual/icons-curtin";

// ------------------------------------------------------------------
//   Variables
// ------------------------------------------------------------------

$site-navi-arrow-background-color: $black !default;

// ------------------------------------------------------------------
//   Site navigation
//   Used in tablet landscape view and above
// ------------------------------------------------------------------
.master-header__content {
    line-height: 1;
    background: $white;
}

// Display More / Less toggle when there are too many navigation items
.show-nav-less,
.show-nav-more {
    display: none;
}

#bilya:target .show-nav-more {
    display: none;
}

#bilya:target .show-nav-less,
#bilya:target .is-hide {
    display: block;
}

// Level 1
.site-navi {
    display: none;    
    .wrapper {
        position: relative; // Make the sub navigation fit within the wrapper container
        @include mq($max: tablet-land) {
            width: 100%;
        }
    }
    @include mq(tablet-land) {
        display: block;                    
        ul {
            @include no-bullets; // Remove bullet from all lists
        }
        .site-navi__layout {
            border-left: 1px solid $grey25;
            &:before,
            &:after {
                content: " ";
                display: table;
            }
            &:after {
                clear: both;
            }
            // Level 1
            > li {
                float: left;
                border-right: 1px solid $grey25;
                border-bottom: 1px solid $grey25;
                margin-bottom: -1px;
                // Mobile only
                &.site-navi__item--mobile {
                    display: none;
                }                
                &> a {
                    display: block;
                    color: $black;
                    text-decoration: none;
                    border-bottom: 4px solid transparent;
                    border-top: 1px solid $white;
                    padding: rem(16px 13px 13px);
                    font-size: rem(14px);
                }
                & > a:focus,
                &:focus > a,
                & > a:active,
                &.is-selected > a {
                    color: $white;
                    background: $secondary-colour;
                }
                & > a:hover,
                &:hover> a {
                    color: $white;
                    background: $black80;
                }
                // Active status
                &.is-active> a,
                &.is-active-ancestor> a {
                    font-weight: $bold;
                }
                // ------------------------------------------------------------------
                // Level 2 - Single drop down menu
                &.has-subnav {
                    > div{
                        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
                        max-height: 0;
                        overflow: hidden;
                        position: absolute;
                        z-index: 1000;

                        a{
                            position: relative;
                            display: block;
                            background: $list-link-background-colour--high-contrast;
                            color: $list-link-colour--high-contrast;
                            text-decoration: none;
                            border-top: 1px solid shade($grey, 55);
                            border-bottom: 1px solid $black;
                            font-size: rem(14px);
                            padding: rem(10px 48px 10px 14px);

                            &:focus {
                                border-color: shade($list-link-background-colour-focus--high-contrast, 20);
                                background-color: $list-link-background-colour-focus--high-contrast;
                                color: $list-link-colour-focus--high-contrast;
                                box-shadow: inset 0px 1px 0 tint($list-link-background-colour-focus--high-contrast, 40);
                            }
                            &:hover {
                                border-color: shade($list-link-background-colour-hover--high-contrast, 20);
                                background-color: $list-link-background-colour-hover--high-contrast;
                                color: $list-link-colour-hover--high-contrast;
                                box-shadow: inset 0 1px 0 tint($list-link-background-colour-hover--high-contrast, 30);
                            }
                            &:active {
                                border-color: shade($list-link-background-colour-active--high-contrast, 20);
                                background-color: $list-link-background-colour-active--high-contrast;
                                color: $list-link-colour-active--high-contrast;
                                box-shadow: inset 0px 1px 0 tint($list-link-background-colour-active--high-contrast, 40);
                            }
                        }                        
                    }
                    &:focus,
                    &:hover,
                    &.is-selected {
                        & > div{
                            max-height: 1000px; //Used to toggle visibility with transition
                        }
                    }                                    
                    > ul {
                        position: absolute;
                        z-index: 1000;
                        left: -9999px;
                        opacity: 0;
                        box-shadow: $box-shadow-x $box-shadow-y $box-shadow-blur $box-shadow-colour;
                        // Show dropdown menu
                        &.show-menu {
                            left: 0;
                            opacity: 1;
                        }
                        li {
                            float: none;
                            position: static;
                            // Link styles
                            a {
                                position: relative;
                                display: block;
                                background: $list-link-background-colour--high-contrast;
                                color: $list-link-colour--high-contrast;
                                text-decoration: none;
                                border-bottom: 1px solid $black90;
                                border-top: 1px solid $black70;
                                width: 200px;
                                font-size: rem(14px);
                                padding: rem($space-s 14px);
                                &:hover {
                                    background: $list-link-background-colour-hover;
                                    color: $black;
                                    border-bottom: 1px solid shade($list-link-background-colour-hover, 20);
                                    border-top: 1px solid tint($list-link-background-colour-hover, 30);
                                }
                                &:active,
                                &:focus {
                                    color: $white;
                                    background: $orange;
                                    border-bottom: 1px solid shade($list-link-background-colour-active, 20);
                                    border-top: 1px solid tint($list-link-background-colour-active, 30);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// ------------------------------------------------------------------
//   Site dropdown navigation
//   Used in mobile view
// ------------------------------------------------------------------
.site-navi--mobile {
    @include mq(tablet-land) {
        display: none;                    
    }
    .site-navi__close {
        @include icon(close, $icon-set: $curtin-icon-set);

        text-align: center;
        font-weight: bold;

        &::before{
            margin-top: -2px;
            margin-right: $space-s;
        }
    }
    &__layout{
        @include no-bullets; // Remove bullets and extra margins
        display: none;

        .select-toggle{
            @include icon(arrow-wide-down, $icon-set: $curtin-icon-set);
        }

        .is-selected > .select-toggle{
            @include icon(arrow-wide-up, $includeStyles: false, $icon-set: $curtin-icon-set);
        }
    }
    &__navi {
        li {
            font-weight: bold;
        }
         ul li {
             font-weight: normal;
         }
    }
    // Level 1
    // ------------------------------------------------------------------
    ul li {
        position: relative;
        line-height: 1;
        // Toggle icon - level 1
        .select-toggle {
            display: block;
            position: absolute;
            top: 0px;
            right: 0;
            border-bottom: 1px solid $site-navi-arrow-background-color;
            border-top: none;
            border-left: 1px solid $site-navi-arrow-background-color;
            background: tint($site-navi-arrow-background-color, 5);
            color: $white;
            cursor: pointer;
            font-style: normal;
            font-size: rem(20px);
            padding: rem(15px $space-l 13px $space-l);
            @include letterpress-black;
            box-shadow: inset 1px 1px 0 tint($site-navi-arrow-background-color, 20);
            &:hover {
                border-color: shade($list-link-background-colour-hover--high-contrast, 20);
                border-left: 1px solid $site-navi-arrow-background-color;
                background-color: $list-link-background-colour-hover--high-contrast;
                color: $list-link-colour-hover--high-contrast;
                box-shadow: inset 1px 1px 0 tint($list-link-background-colour-hover--high-contrast, 40);
            }
            &:focus {
                border-color: shade($list-link-background-colour-focus--high-contrast, 20);
                border-left: 1px solid $site-navi-arrow-background-color;
                background-color: $list-link-background-colour-focus--high-contrast;
                color: $list-link-colour-focus--high-contrast;
                box-shadow: inset 1px 1px 0 tint($list-link-background-colour-focus--high-contrast, 40);
            }
            &:active {
                border-color: shade($list-link-background-colour-active--high-contrast, 20);
                border-left: 1px solid $site-navi-arrow-background-color;
                background-color: $list-link-background-colour-active--high-contrast;
                color: $list-link-colour-active--high-contrast;
                box-shadow: inset 1px 1px 0 tint($list-link-background-colour-active--high-contrast, 40);
            }
        }
        // Toggle icon - if selected, level 1
        &.is-selected> .select-toggle {
            background: $list-link-background-colour-hover--high-contrast;
            border-color: shade($list-link-background-colour-hover--high-contrast, 20);
            border-top: none;
            color: $list-link-colour-hover--high-contrast;
            box-shadow: inset 1px 1px 0 tint($list-link-background-colour-hover--high-contrast, 40);
            @include letterpress($list-link-colour-hover--high-contrast, 0.3);
            &:hover {
                border-color: shade($list-link-background-colour-hover--high-contrast, 20);
                border-left: 1px solid shade($list-link-background-colour-hover--high-contrast, 20);
                background-color: $list-link-background-colour-hover--high-contrast;
                color: $list-link-colour-hover--high-contrast;
                box-shadow: inset 1px 1px 0 tint($list-link-background-colour-hover--high-contrast, 40);
            }
            &:focus {
                border-color: shade($list-link-background-colour-active--high-contrast, 20);
                border-left: 1px solid shade($list-link-background-colour-hover--high-contrast, 20);
                background-color: $list-link-background-colour-focus--high-contrast;
                color: $list-link-colour-focus--high-contrast;
                box-shadow: inset 1px 1px 0 tint($list-link-background-colour-active--high-contrast, 40);
            }
            &:active {
                border-color: shade($list-link-background-colour-active--high-contrast, 20);
                border-left: 1px solid shade($list-link-background-colour-hover--high-contrast, 20);
                background-color: $list-link-background-colour-active--high-contrast;
                color: $list-link-colour-active--high-contrast;
                box-shadow: inset 1px 1px 0 tint($list-link-background-colour-active--high-contrast, 40);
            }
        }
        // nav item
        &.is-selected> a {
            background: $list-link-background-colour-hover--high-contrast;
            color: $list-link-colour-hover--high-contrast;
            border-color: shade($list-link-background-colour-hover--high-contrast, 20);
            font-weight: $bold;
            box-shadow: inset 0 1px 0 tint($list-link-background-colour-hover--high-contrast, 30);
            &:after {
                content: '';
                left: 4%;
                top: 47px; //2.75em;
                position: absolute;
                z-index: 100;
                @include triangle(16px, 8px, down, $list-link-background-colour-hover--high-contrast);
            }
            &:focus {
                border-color: shade($list-link-background-colour-active--high-contrast, 20);
                background-color: $list-link-background-colour-focus--high-contrast;
                color: $list-link-colour-focus--high-contrast;
                box-shadow: inset 0px 1px 0 tint($list-link-background-colour-active--high-contrast, 40);
                &+.select-toggle {
                    border-left: 1px solid shade($list-link-background-colour-active--high-contrast, 20);
                }
                &:after {
                    @include triangle(16px, 8px, down, $list-link-background-colour-active--high-contrast);
                }
            }
            &:active {
                border-color: shade($list-link-background-colour-active--high-contrast, 20);
                background-color: $list-link-background-colour-active--high-contrast;
                color: $list-link-colour-active--high-contrast;
                box-shadow: inset 0px 1px 0 tint($list-link-background-colour-active--high-contrast, 40);
                &+.select-toggle {
                    border-left: 1px solid shade($list-link-background-colour-active--high-contrast, 20);
                }
                &:after {
                    @include triangle(16px, 8px, down, $list-link-background-colour-active--high-contrast);
                }
            }
        }
        // Hide desktop only links
        &.site-navi__item--tl {
            display: none;
        }
    }
    // nav item - level 1
    a {
        display: block;
        background: $black90;
        color: $white;
        text-decoration: none;
        white-space: nowrap; // prevent the text from wrapping
        border-bottom: 1px solid $black;
        padding: $space-m 3%;
        box-shadow: inset 0 1px 0 $black80;
        font-size: rem(16px);
        &:focus {
            border-color: shade($list-link-background-colour-active--high-contrast, 20);
            background-color: $list-link-background-colour-focus--high-contrast;
            color: $list-link-colour-focus--high-contrast;
            box-shadow: inset 0px 1px 0 tint($list-link-background-colour-active--high-contrast, 40);
            &+.select-toggle {
                border-left: 1px solid shade($list-link-background-colour-active--high-contrast, 20);
            }
        }
        &:hover {
            border-color: shade($list-link-background-colour-hover--high-contrast, 20);
            background-color: $list-link-background-colour-hover--high-contrast;
            color: $list-link-colour-hover--high-contrast;
            box-shadow: inset 0 1px 0 tint($list-link-background-colour-hover--high-contrast, 30);
            &+.select-toggle {
                border-left: 1px solid shade($list-link-background-colour-hover--high-contrast, 20);
            }
        }
        &:active {
            border-color: shade($list-link-background-colour-active--high-contrast, 20);
            background-color: $list-link-background-colour-active--high-contrast;
            color: $list-link-colour-active--high-contrast;
            box-shadow: inset 0px 1px 0 tint($list-link-background-colour-active--high-contrast, 40);
            &+.select-toggle {
                border-left: 1px solid shade($list-link-background-colour-active--high-contrast, 20);
            }
        }
    }
    // Level 2
    // ------------------------------------------------------------------
    ul ul {
        display: none; // Hidden from dropdown
        margin-bottom: 0;

        li {
            .select-toggle {
                background: tint($black, 12);
                border-color: $black95;
                border-top: none;
                box-shadow: inset 1px 1px 0 $black75;
            }
            // Nav items - level 2 and below
            a {
                border-color: $black90;
                background: $black85;
                text-shadow: none;
                text-transform: none;
                font-size: rem(16px);
                padding-left: 5%;
                box-shadow: inset 0 1px 0 $black75;
            }
            // Toggle icon - if selected, level 2
            &.is-selected> .select-toggle {
                background: tint($list-link-background-colour-hover--high-contrast, 20);
                border-color: shade($list-link-background-colour-hover--high-contrast, 20);
                box-shadow: inset 1px 1px 0 tint($list-link-background-colour-hover--high-contrast, 40);
            }
            // Nav items - if selected, level 2
            &.is-selected> a {
                background: tint($list-link-background-colour-hover--high-contrast, 20);
                border-top: none;
                font-weight: $bold;
                box-shadow: inset 0 1px 0 tint($list-link-background-colour-hover--high-contrast, 30);
                // Bottom triangle
                &:after {
                    left: 6%;
                    @include triangle(16px, 8px, down, tint($list-link-background-colour-hover--high-contrast, 20));
                }
            }
            // Top triangle shadow
            &:first-child> a {
                &:before {
                    content: '';
                    left: 4%;
                    top: 0;
                    position: absolute;
                    z-index: 50;
                    @include triangle(16px, 8px, down, transparentize($black, 0.8));
                }
            }
        }        
    }

    // Level 3
    // ------------------------------------------------------------------
    ul ul ul li {      
        &.is-selected> a:after {
            left: 8%;
        }
        .select-toggle {
            background: tint($black, 16);
        }
        a {
            background: $black80;
            border-color: $black85;
            font-weight: $medium;
            text-transform: none;
            font-size: rem(16px);
            padding-left: 7%;
            box-shadow: inset 0 1px 0 $black75;
        }
        // Toggle icon - If selected, level 3
        &.is-selected> .select-toggle {
            background: tint($list-link-background-colour-hover--high-contrast, 40);
            box-shadow: inset 1px 1px 0 tint($list-link-background-colour-hover--high-contrast, 50);
        }
        // Nav items - if selected, level 3
        &.is-selected> a {
            background: tint($list-link-background-colour-hover--high-contrast, 40);
            border-top: none;
            font-weight: $bold;
            box-shadow: inset 1px 1px 0 tint($list-link-background-colour-hover--high-contrast, 50);
            // Triangle
            &:after {
                left: 8%;
                @include triangle(16px, 8px, down, tint($list-link-background-colour-hover--high-contrast, 40));
            }
        }
        // Top triangle shadow
        &:first-child> a {
            &:before {
                content: '';
                left: 6%;
                top: 0;
                position: absolute;
                z-index: 50;
                @include triangle(16px, 8px, down, transparentize($black, 0.8));
            }
        }
    }

    // Level 4
    // ------------------------------------------------------------------
    ul ul ul ul li {
        &:first-child {
            // Stroke
            &:after {
                left: 8%;
            }
            &.is-selected> a:before {
                left: 8%;
            }
        }
        a {
            padding-left: 9%;
        }
        // Top triangle shadow
        &:first-child> a {
            &:before {
                content: '';
                left: 8%;
                top: 0;
                position: absolute;
                z-index: 50;
                @include triangle(16px, 8px, down, transparentize($black, 0.8));
            }
        }
    }
}