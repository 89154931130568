@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/units";
@import "mixins/layout";
@import "mixins/text";

@at-root {
    html {
        font-size: 100%;
        @include adjust-leading-to(1);
    }
    body {
        position: relative;
        color: $text-colour;
        font-family: $base-font-family;
        font-weight: $base-font-weight;
        -webkit-font-smoothing: subpixel-antialiased;
        text-rendering: optimizeLegibility;
    }
    html,
    body {
        height: 100%;
        background: $body-colour;
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

p {
    //margin: 0 0 1em;
    margin: 0 0 $space-m;
}

ul,
ol {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin-left: $space-l;
        &:last-child {
            margin-bottom: 0;
        }
    }    
}

.main-content,
.secondary-content {
    ol,
    ul {
        font-size: rem(16px);
        @include mq(tablet-port) {
            font-size: rem(17px);
        }
        li {
            margin-top: $space-s;
            margin-bottom: $space-s;
        }
    }
}

ul> li {
    list-style: disc;
}

ol> li {
    list-style: decimal;
}

// Definition list
dl {
    margin: 0 0 $space-m;
    >dt:first-of-type {
        margin-top: 0;
    }
}

dt {
    margin-top: rem(16px);
}

dd {
    margin: 0;
}

small {
    display: block;
    font-size: rem(14px);
}

img {
    max-width: 100%;
    height: auto;
    border-width: 0;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic;
}
