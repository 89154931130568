@import "mixins/layout";
.featured-panel {
    // ------------------------------------------------------------------
    //   Primary panel within top panel
    // ------------------------------------------------------------------
    &__primary {
        @include span-columns(7);
        @include mq(tablet-land) {
            margin-bottom: 0;
        }
    }
    // ------------------------------------------------------------------
    //   Secondary panel within top panel
    // ------------------------------------------------------------------
    &__secondary {
        @include span-columns(5);
        @include last;
    }
}

// ------------------------------------------------------------------
//   Panel Layout One: 7 - 5 col
// ------------------------------------------------------------------
.panel--layout-one {
    .featured-panel {
        &__primary {
            @include span-columns(7);
        }
        &__secondary {
            @include span-columns(5);
        }
    }
}

// ------------------------------------------------------------------
//   Panel Layout Two: 5 - 7 col
// ------------------------------------------------FD------------------
.panel--layout-two {
    .featured-panel {
        &__primary {
            @include span-columns(5);
        }
        &__secondary {
            @include span-columns(7);
        }
    }
}

// ------------------------------------------------------------------
//   Panel Layout Three: 6 - 6 col
// ------------------------------------------------------------------
.panel--layout-three {
    .featured-panel {
        &__primary,
        &__secondary {
            @include span-columns(6);
        }
    }
}