@import "variables/colour";
@import "functions/layout";
@import "functions/units";
@import "mixins/links";

// Tags
.tags {
    text-transform: uppercase;
    a {
        display: inline-block;
        padding: rhythm(1/3, 13px) rhythm(1/2, 13px);
        background: $black;
        text-decoration: none;
        border-bottom: none;
        margin: rem(0 0.5 0.8 0);
        font-size: rem(13px);
        @include list-link-colour--high-contrast( $background: $black );
        color: $white; // Overwrite text colour
        &:visited {
            color: $white; // Overwrite text colour
            &:hover {
                color: $black80;
            }
        }
        &:focus,
        &:active {
            color: $white !important; // dark grey text was showing up on :focus and :active states on already visited links
        }
    }
}