@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/units";
@import "mixins/border";
@import "mixins/layout";
@import "mixins/links";
@import "elements/typography";

// ------------------------------------------------------------------
//   Module title
// ------------------------------------------------------------------
.module-title {
    padding: 0;
    font-family: $header-font-family;
    font-weight: $regular;
    margin: $space-s 0;
    font-size: rem(20px);
    a {
        @include link-colour($black60, $visited: $black60, $border: false);
    }
}

.box--high-contrast .module-title a {
    @include link-colour--high-contrast($border: false);
}

// Secondary content column
.secondary-content .module-title {
    @include mq(tablet-land) {
        font-size: rem(18px);
    }
}

// Module title with underline
.module-title--underline {
    @extend .module-title; // Inherit the styles from module title
    @extend .underline;
    @include border-shaded(down); // Letterpress border
    padding-bottom: $space-s;
    @include mq(wide-screen) {
        padding-bottom: $space-s;
    }
}