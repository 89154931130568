@import "templates/box";

@mixin box(
    $template: '%box', 
    $template-header: '%box__header', 
    $template-content: '%box__content', 
    $template-footer: '%box__footer'
    ) {
    @extend #{$template};

    & {
        &__header{
            @extend #{$template-header};

            &__title{
                @extend #{$template-header}__title;
            }
        }

        &__content{
            @extend #{$template-content};
        }

        &__footer{
            @extend #{$template-footer};
        }
    }
}

@mixin status-box($color, $background-colour, $header-selector: '.box__header'){
    border-color: $background-colour;
    
    #{$header-selector}{
        background-color: $background-colour;

        &,
        & > * {
            color: $color;
        }
    }
}
