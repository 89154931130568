@import "vendor/language-selector";
@import "variables/border";
@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/colour";
@import "functions/units";
@import "mixins/input";
@import "mixins/border";
@import "mixins/icons";
@import "mixins/layout";
@import "mixins/lists";
@import "mixins/text";
@import "elements/toggle-content";
@import "components/header";

// ------------------------------------------------------------------
//   Variables
// ------------------------------------------------------------------
$global-bar-background-color: $black80 !default;
$global-bar-border-colour: $gold !default;


// ------------------------------------------------------------------
//   Global bar
// ------------------------------------------------------------------
.global-bar {
    @include clearfix;
    background-color: $global-bar-background-color;
    border-bottom: 3px solid $global-bar-border-colour;
    
    &__inner {
        @include clearfix;
        padding-top: rem(12px);
        padding-bottom: rem(12px);
    }
    &__inner--header {
        line-height: 10px; // Remove the extra bottom spacing
        position: relative;
        z-index: 1;
    }
    &__inner--footer {
        display: none; 
        position: relative;
        border-top: none;
        height: rem(56px);
        margin-top: rem(-56px);
        text-align: right;
        padding: 0;
        background: none;
        @include mq(tablet-land) {
            display: block;
        }
    }

    // ------------------------------------------------------------------
    //   Global bar links
    //   Used in tablet landscape view and above
    // ------------------------------------------------------------------
    &__links {
        display: none;
        padding: 0;
        font-size: 13px;
        @include no-bullets;
        // tablet landscape
        @include mq(tablet-land) {
            display: inline-block;
            margin-top: rem(15px);
            position: relative;
            z-index: 1;            
        }
        @include mq(wide-screen) {
            padding-left: $space-l;
        }
        li {
            margin: 0;
            float: left;
            &:first-child {
                border-left: none;
                box-shadow: none;
            }
            @include border-shaded--high-contrast(left);
            a {
                float: left;
                text-decoration: none;
                padding: rem(0 $space-s);
                @include letterpress-black; // Text drop shadow
                @include link-colour($normal: $white, $hover: $yellow, $active: $white, $visited: $white, $focus: $black, $border: false); // Link colours
                &:focus {
                    text-shadow: none; // Remove text drop shadow on focus
                }
                @include mq(tablet-land) {
                    padding: 0 $space-s;
                }
                @include mq(wide-screen) {
                    padding: 0 $space-m;
                }
            }
        }
    }

    // ------------------------------------------------------------------
    //   OASIS login links
    //   Used in tablet landscape view and above
    // ------------------------------------------------------------------
    &__login {
        display: none;
        padding: 0;
        background: $black70;
        font-size: rem(13px);
        @include mq(tablet-land) {
            display: inline-block;
            margin-bottom: 0;
            margin-top: rem(9px);
            vertical-align: top;
            position: relative;
            z-index: 1;
        }
        li {
            display: inline;
            margin: 0;
            border-right: 1px solid $black;
            box-shadow: 1px 0px 0px #4b4b4b;
            a {
                display: inline-block;
                padding-top: rhythm(1/4, 14px);
                padding-bottom: rhythm(1/4, 14px);
                text-decoration: none;
                @include mq(tablet-land) {
                    padding-left: 8px;
                    padding-right: 8px;
                }
                @include mq(wide-screen) {
                    padding-left: $space-m;
                    padding-right: $space-m;
                }
                @include letterpress-black; // Text drop shadow
                @include link-colour($normal: $white, $hover: $yellow, $active: $black, $visited: $white, $focus: $black, $border: false); // Link colours
                &:focus {
                    text-shadow: none; // Remove text drop shadow on focus
                }
            }
            &:last-child {
                border-right: none;
                box-shadow: none;
            }
        }
    }

    // ------------------------------------------------------------------
    //   OASIS login buttons
    //   Used in mobile view
    // ------------------------------------------------------------------
    &__login--mobile {
        @include clearfix;
        @include toggle-content;
        position: relative;
        top: -2px; // hide the bottom inset shadow
        background: $black;
        background-image: none; // reset gradient overlay
        @include no-bullets;
        @include mq(tablet-land) {
            display: none;
        }
        > li {
            width: 48%;
            float: left;
            margin: 0 4% 0 0;
            &.global-bar__login--staff {
                margin-right: 0;
            }
        }
        .global-bar__login--student a {
            @include action-input(
                $colour: $white,
                $background-colour: shade($oasis-student, 20),
                $background-image: linear-gradient(to top, #006597 0%, #007bb5 50.76%, #0087c9 54.25%, #0d8dcd 100%),
                $border-colour: #006697,
                $box-shadow: (0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45)),
                $text-shadow: 0 -1px 0 rgba(0, 0, 0, .4),

                $colour-hover: $white,
                $background-colour-hover: shade($oasis-student, 10),
                $background-image-hover: linear-gradient(to top, #005983 0%, #006597 50.76%, #0073ab 54.25%, #0087c9 100%),
                $border-colour-hover: #ffbf00,
                $box-shadow-hover: (0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45)),

                $colour-active: $white,
                $background-colour-active: shade($oasis-student, 30),
                $background-image-active: linear-gradient(to top, #0087c9 0%, #0087c9 45.12%, #0087c9 49.19%, #006597 100%),
                $border-colour-active: #006697,
                $box-shadow-active: (0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45)),
                $template: '%button'
            );

            margin-bottom: 0;
        }

        .global-bar__login--staff a {
            @include action-input(
                $colour: $white,
                $background-colour: shade($oasis-staff, 20),
                $background-image: linear-gradient(to top, #003b53 0%, #004563 50.76%, #0d4f6b 54.25%, #195771 100%),
                $border-colour: #004563,
                $box-shadow: (0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .5)),
                $text-shadow: 0 -1px 0 rgba(0, 0, 0, 1),

                $colour-hover: $white,
                $background-colour-hover: shade($oasis-staff, 10),
                $background-image-hover: linear-gradient(to top, #003145 0%, #00374f 50.76%, #00415d 54.25%, #004563 100%),
                $border-colour-hover: #ffbf00,
                $box-shadow-hover: (0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .5)),
                
                $colour-active: $white,
                $background-colour-active: shade($oasis-staff, 30),
                $background-image-active: linear-gradient(to top, #004563 0%, #256179 45.12%, #256179 49.19%, #00374f 100%),
                $border-colour-active: #004563,
                $box-shadow-active: (0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45)),
                $template: '%button'
            );

            margin-bottom: 0;            
        }
        
        .global-bar__login--student a,
        .global-bar__login--staff a {
            width: 100%;
            text-align: center;
            padding-left: 5px;
            padding-right: 5px;
        }
    }  

    // ------------------------------------------------------------------
    //   Language switcher
    //   Used in tablet landscape view and above
    // ------------------------------------------------------------------
    &__language {
        z-index: 1;
        padding: 0;
        position: absolute;
        width: 46%;
        top: 12px;
        @include mq(tablet-land) {
            position: relative;
            display: inline-block;
            float: right;
            margin-bottom: 0;
            width: 15%;
            top: 0;
            margin-top: rem(-49px);
        }
    }      
}