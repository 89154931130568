@import "variables/layout";
@import "functions/units";
@import "mixins/icons";
@import "mixins/text";

// ------------------------------------------------------------------
// Validation icon
// ------------------------------------------------------------------
%form-validation--icon {
    padding-right: $space-s;
    margin-bottom: rem(3px);
}

// ------------------------------------------------------------------
// Validation message global
// ------------------------------------------------------------------
%form-validation--message--page {
    margin-bottom: $space-l;
    padding: $space-m $space-m $space-m rem(56px);

    &:before {
        margin-left: rem(-40px);        
        margin-bottom: rem(3px);
        float: left;
        font-size: rem(30px);
    }

    ul,
    ol {
        margin-bottom: 0;
    }
    li {
        margin-top: 0;
        margin-bottom: 0;
    }
}

// ------------------------------------------------------------------
// Validation message inline
// ------------------------------------------------------------------
%form-validation--message--inline {
    position: relative;
    display: block;
    transition: display 0.3s ease-in;
    font-size: rem(15px);
    @include adjust-leading-to(0.9);
    padding: rem(5px 0);
    
    &:before {
        padding-right: $space-s;
        margin-bottom: rem(3px);
    }
}

%form-validation--highlight {
    @include clearfix;
    padding: $space-s $space-m;
    margin-bottom: $space-m;
}