@import "variables/font";
@import "variables/layout";

@function rhythm($lines: 1, $font-size: $base-font-size, $offset: 0) {
    @if unit($font-size) !='px' {
        @warn "Paremeter $font-size must resolve to a value in pixel units.";
    }
    $rhythm: ($lines * $base-line-height - $offset) * 1em / $font-size;
    @return $rhythm;
}

@function space( $columns, $total-columns: $total-columns, $column-width: $column-width, $gutter-width: $gutter-width) {
    @return columns($columns, $total-columns, $column-width, $gutter-width)+ if($columns>=1, gutter($total-columns, $column-width, $gutter-width), 0);
}

@function gutter( $total-columns: $total-columns, $column-width: $column-width, $gutter-width: $gutter-width) {
    @return relative-width($gutter-width, $total-columns, $column-width, $gutter-width);
}

@function columns-width( $columns: $total-columns, $column-width: $column-width, $gutter-width: $gutter-width) {
    @return ($columns * $column-width)+ (if($columns>=1, ($columns - 1), 0) * $gutter-width);
}

@function relative-width( $width, $total-columns: $total-columns, $column-width: $column-width, $gutter-width: $gutter-width) {
    @return percentage($width / columns-width($total-columns, $column-width, $gutter-width));
}

@function columns( $columns, $total-columns: $total-columns, $column-width: $column-width, $gutter-width: $gutter-width) {
    @return relative-width(columns-width($columns, $column-width, $gutter-width), $total-columns, $column-width, $gutter-width);
}

@function block-grid-item-width($per-row, $spacing){
    @return ((100% - (($per-row - 1) * $spacing)) / $per-row);
}