@import "variables/colour";
@import "variables/layout";
@import "functions/layout";
@import "functions/units";
@import "mixins/layout";

// ------------------------------------------------------------------
//   Standard Panel
// ------------------------------------------------------------------
.panel {
    // To be removed eventually - use .primary--panel and .secondary--panel
    @include clearfix; // clearfix
    position: relative;
    z-index: 100;
    background-color: $white;
    box-shadow: $box-shadow-colour $box-shadow-x $box-shadow-y $box-shadow-blur;
    padding: $space-x 0;
    .module {
        margin-bottom: $block-margin-bottom;
        &:last-of-type {
            margin-bottom: 1em;
        }
    }
    &__content {
        p {
            font-size: rem(15px);
            @include adjust-leading-to(0.85);
        }
    }

    &--primary,
    &--secondary {
        @include clearfix;

        background-color: $white;
        border-bottom: 1px solid $border-colour;
        padding: $space-l 0;
        box-shadow: $box-shadow-colour $box-shadow-x $box-shadow-y $box-shadow-blur;
    }
    &--primary {
        @include mq(tablet-land) {
            padding: $space-x 0;
        }
        @include mq(wide-screen) {
            padding: $space-xx 0;
        }
    }
    &--secondary {
        border-top: 1px solid $border-colour;
        @include mq(tablet-port) {
            padding: $space-x 0;
        }
        & .row {
            @include mq(tablet-small) {
                display: flex;
            }
        }
    }

    // ------------------------------------------------------------------
    //   two column panel
    // ------------------------------------------------------------------
    &--two-columns {
        .panel__content {
            margin-right: 0;
            @include span-columns(9, 12, 4.5%, 4%);
            @include mq(tablet-small) {
                @include span-columns(12, 12, 4.5%, 4%);
            }
            @include mq(tablet-land) {
                @include span-columns(7, 12, 4.5%, 4%);
            }
        }
        .panel__image {
            margin-bottom: $space-l;
            @include span-columns(3, 12, 4.5%, 4%);
            @include mq(tablet-small) {
                @include span-columns(12, 12, 4.5%, 4%);
            }
            @include mq(tablet-land) {
                margin-bottom: 0;
                @include span-columns(5, 12, 4.5%, 4%);
            }
            img {
                width: 100%;
            }
        }
    }    
}
