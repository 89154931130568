@import "variables/animation";
@import "variables/border";
@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/colour";
@import "functions/units";
@import "mixins/border";
@import "mixins/icons";
@import "mixins/layout";
@import "mixins/links";
@import "mixins/lists";
@import "mixins/text";
@import "elements/toggle-content";

// ------------------------------------------------------------------
//   Page header
// ------------------------------------------------------------------
.page-header {
    @include clearfix;

    position: relative;
    z-index: 110;
    box-shadow: $box-shadow-x $box-shadow-y $box-shadow-blur $box-shadow-colour;

    *{
        transition: 
            color $transition-speed $transition-style, 
            border-color $transition-speed $transition-style, 
            background-color $transition-speed $transition-style,
            box-shadow $transition-speed $transition-style,
            max-height $transition-speed $transition-style;
    }
    .subsite-header {
        background-color: $header-background-colour;
        @include letterpress($header-colour, $opacity: 0.5);

        &:hover {
            background-color: shade($header-background-colour, 15);
        }

        @include mq($max: tablet-land){
            .wrapper{
                margin-left: 0;
                display: table;
            }
        }

        &--mobile{
            @extend .subsite-header;

            @include mq(tablet-land) {
                display: none;
            }
        }
    }
    .subsite-title {
        // Link
        a {
            display: block;
            padding: 0;
            @include link-colour($normal: $header-colour, $visited: $header-colour, $hover: $header-colour, $active: $header-colour, $border: false);
        }
    }
}

// ------------------------------------------------------------------
//   Site logo
// ------------------------------------------------------------------
.site-logo {
    display: inline-block;
    float: left;
    border: none;
    height: rem(32px);
    width: rem(184px);
    @include link-colour($border: false);
    img {
        display: block;
    }
}

// ------------------------------------------------------------------
//   Global site search
// ------------------------------------------------------------------
.site-search {
    @include clearfix; // clearfix
    @include toggle-content;
    padding: rem($gutter-width);
    margin-bottom: 0;
    @include mq(tablet-land) {
        display: inline-block !important; // Force to display
        position: relative;
        background: none;
        padding: 0;
        @include span-columns(4);
        margin-left: $space-m;
    }
    @include mq(wide-screen) {
        @include span-columns(4);
        margin: rem(0 0 0 $gutter-width);
    }
}

// ------------------------------------------------------------------
// No JS fallback
.no-js .site-search {
    margin: rem(2px 0 0 48px);
}

// ------------------------------------------------------------------
// Global search field and button
input.site-search__field,
.site-search__button {
    display: block;
    float: left;
}

// ------------------------------------------------------------------
// Global search field
input[type].site-search__field {
    margin: 0 !important;
    display: inline-block;
    border: 1px solid $grey60;
    color: $black65;
    font-family: $base-font-family;
    outline: none;
    vertical-align: middle;
    line-height: normal;
    appearance: none;
    border-radius: 3px;
    box-sizing: border-box; // Overwrite _normalize
    padding: $space-xs $space-s;
    box-shadow: inset -1px 1px 4px rgba(0, 0, 0, .16);
    background: $white;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: rem(32px);
    @include span-columns(8);
    @include mq(tablet-port,
    tablet-land) {
        @include span-columns(9);
    }
    @include mq(wide-screen) {
        @include span-columns(9);
    }
    &:focus {
        background-color: $white;
        border: 1px solid $blue;
    }
}

// ------------------------------------------------------------------
//   Additional language switcher styles
// ------------------------------------------------------------------
#lang_sel {
    display: block;
    font-family: $base-font-family;
    width: 100%;
    @include mq(tablet-land) {
        width: auto;
    }
    ul {
        >li {
            display: block;
            width: 100%;
            &:hover {
                >a.lang_sel_sel {
                    height: 50px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    color: $yellow;
                }
                >ul {
                    top: rem(50px);
                }
            }
            @include mq(tablet-land) {
                display: inline-block;
                //width: auto;
                &:hover {
                    >a.lang_sel_sel {
                        height: auto;
                    }
                    >ul {
                        top: rem(32px);
                    }
                }
            }
        }
    }
    // Active language visible
    a.lang_sel_sel {
        line-height: normal;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
        outline: none;
        -webkit-appearance: none; // Remove platform specific styles
        background-clip: padding-box; // Prevents bg colour from leaking outside the border
        border-radius: 3px; // Border radius
        font-size: $button-font-size;
        user-select: none;
        background-color: $black80;
        border: 1px solid $black; // Overwrite link style
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 1);
        color: $white;
        display: block;
        margin-right: 4%;
        margin-bottom: 0;
        width: 100%;
        float: left;
        font-family: $header-font-family;
        text-align: left;
        &:after {
            @include arrow($wide: true, $direction: down, $position: absolute);
            font-size: rem(14px);
            @include mq(tablet-land) {
                top: 60%;
                font-size: rem(10px);
            }
        }
        &:hover,
        &:focus {
            // Hover and focus status
            background-color: $black70;
            border-color: $black; // Overwrite link style
            color: $yellow;
            box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .13);
            background-image: linear-gradient(to top, #0d0d0d 0%, #252525 49.76%, #333 53.76%, #3f3f3f 100%);
        }
        &:active {
            // Active status
            background-color: $black95;
            border-color: $black; // Overwrite link style
            color: $white; // Overwrite link style
            box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .13);
            background-image: linear-gradient(to top, #252525 0%, #252525 37.74%, #252525 47%, $black 100%);
        }
        @include mq(tablet-land) {
            background-image: none;
            background: $black70;
            margin: 0;
            border: none;
            border-radius: 0;
            box-shadow: none;
            font-family: $base-font-family;
            text-transform: none;
            text-shadow: none;
            padding: $space-s $space-m;
            font-size: rem(13px);
            @include link-colour($normal: $white, $hover: $yellow, $active: $white, $visited: $white, $focus: $black, $border: false);
            &:hover {
                background-image: none;
                background: $white;
                border: none;
                border-radius: 0;
                box-shadow: none;
                height: auto;
                color: $black80;
            }
        }
    }
    // Dropdown
    ul ul {
        display: block;
        height: auto;
        width: 100%;
        border: none;
        top: rem(40px);
        box-shadow: $box-shadow-x $box-shadow-y $box-shadow-blur $box-shadow-colour;
        @include mq(tablet-land) {
            display: inline-block;
        }
        // Dropdown item
        li {
            display: inline-block;
            width: 100%;
            a {
                display: block;
                position: relative;
                background: $black90;
                border-top: 1px solid shade($grey, 55);
                border-bottom: 1px solid $black;
                border-left: 1px solid $black;
                border-right: 1px solid $black;
                text-decoration: none;
                @include list-link-colour--high-contrast($background: $black80);
                padding: rem($space-s $space-m);
                font-size: rem(16px);
                &:hover {
                    border-top: 1px solid $yellow70;
                    border-bottom: 1px solid shade($yellow, 20);
                    border-left: 1px solid shade($yellow, 20);
                    border-right: 1px solid shade($yellow, 20);
                }
                @include mq(tablet-land) {
                    display: block;
                    background: $black70;
                    color: $white;
                    border: none;
                    border-top: 1px solid $black60;
                    border-bottom: 1px solid $black80;
                    border-left: 1px solid $black70;
                    border-right: 1px solid $black70;
                    font-size: rem(13px);
                    &:hover {
                        background: shade($white, 5);
                        color: $black80;
                        border-top: 1px solid $white;
                        border-bottom: 1px solid shade($white, 15);
                        border-left: $white;
                        border-right: $white;
                    }
                }
            }
        }
    }
    // Flags
    img.iclflag {
        top: -1px;
        margin-right: rem($space-xs);
    }
}

// ------------------------------------------------------------------
//   Global search button
// ------------------------------------------------------------------
.search-button--global {
    display: inline-block;
    line-height: normal !important;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    cursor: pointer;
    font-family: "GandhiSansBold", Arial;
    outline: none;
    -webkit-appearance: none; // Remove platform specific styles
    background-clip: padding-box; // Prevents bg colour from leaking outside the border
    border-radius: 3px; // Border radius
    font-size: $button-font-size;
    user-select: none; // Text unselectable
    padding: rem(6px) $space-m;
    background-color: $yellow;
    border: 1px solid #cb9900; // Overwrite link style
    color: $black;
    text-shadow: white(0.5) 0 1px 0;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0 !important;
    text-align: center;
    @include span-columns(4);
    @include mq(tablet-port,
    tablet-land) {
        @include span-columns(3);
    }
    @include mq(wide-screen) {
        @include span-columns(3);
    }
    &:hover,
    &:focus {
        // Hover and focus status
        background-color: $yellow80;
        border-color: $yellow; // Overwrite link style
        box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45);
    }
    &:active {
        // Active status
        background-color: shade($yellow, 5);
        border-color: #cb9900; // Overwrite link style
        box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45);
    }
}