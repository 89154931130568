@mixin state-colours($colours, $background-colours: (), $default-background: false) {
    color: map-get($colours, normal);
    
    @if $default-background {
        @if $default-background == true{
            background-color: map-get($background-colours, normal);
        }
        @else {
            background-color: $default-background;
        }
    }

    @each $state in (visited, focus, hover, active){
        &:#{$state}{
            color: map-get($colours, $state);
            background-color: map-get($background-colours, $state);
        }
    }
}