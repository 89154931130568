@import "variables/colour";
@import "variables/font";
@import "functions/colour";
@import "functions/layout";
@import "functions/units";
@import "mixins/text";

// ------------------------------------------------------------------
//   Tables
// ------------------------------------------------------------------
.table-responsive {
    position: relative;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    border: 1px solid $box-border-colour;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    margin-bottom: $space-l;
    // Tighten up spacing
    table {
        width: 100%;
        thead {
            // Table head
            background-color: $white;
            th {
                border-bottom: 3px solid $black20;
                font-weight: bold;
                text-align: left;
                vertical-align: top;
                white-space: nowrap;
                line-height: rem(24px);
                padding: rem(8px 19px);
                font-size: rem(15px);
                @include adjust-leading-to(1);
            }
        }
        // Ensure the content doesn't wrap
        >tbody,
        >tfoot {
            >tr {
                >th,
                >td {
                    text-align: left;
                    vertical-align: top;
                    line-height: rem(24px);
                    padding: rem(8px $space-m);
                    font-size: rem(15px);
                    @include adjust-leading-to(0.9);
                }
                &:nth-child(odd) {
                    background-color: tint($black, 97);
                }
                &:nth-child(even) {
                    background-color: $white;
                }
            }
        }
    }
    +small {
        margin: (-$space-s) 0 $space-m;
    }
}

p+.table-responsive {
    margin-top: $space-l;
}

.btn-toolbar {
    margin-bottom: rem(20px);
}

// ------------------------------------------------------------------
//   Alignment
// ------------------------------------------------------------------
.vertical-align {
    vertical-align: center;
}

// ------------------------------------------------------------------
//   Sticky table head
// ------------------------------------------------------------------
.sticky-table-head {
    background-color: $white;
    border: none;
    border-radius: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    z-index: 990;
    overflow: hidden;
    min-width: 100%;
    border-top: 1px solid $black20;
    border-bottom: 1px solid $black20;
    box-shadow: 0 2px 5px black(0.20);
    &.fixed-solution {
        min-width: 0;
        position: fixed;
    }
}

// ------------------------------------------------------------------
//   The column toggle dropdown menu (ul)
// ------------------------------------------------------------------
.js-table-column-toggle {
    max-width: 320px;
    >li.checkbox-row {
        display: block;
        clear: both;
        color: $black80;
        font-weight: $medium;
        white-space: nowrap;
        line-height: rem(20px);
        padding: rem(5px 20px);
    }
    input[type="checkbox"] {
        float: left;
        display: block;
        margin-top: rem(4px);
    }
    label {
        font-weight: $medium;
        display: block;
        margin: 0;
        cursor: pointer;
        font-weight: $medium;
        font-size: rem($base-font-size);
        line-height: rem(23px);
        padding-left: rem(25px);
    }
}

