// ------------------------------------------------------------------
//   Screen reader content
//   Hide only visually, but have it available for screenreaders
//   If the element if focusable (eg. skip to content link) display
//   it on focus, normally done via keyboard navigation
// ------------------------------------------------------------------
.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &:focus{
        border: initial;
        clip: initial;
        height: initial;
        margin: initial;
        overflow: initial;
        padding: initial;
        position: initial;
        width: initial;
    }
}

// ------------------------------------------------------------------
//   Show and hide ele,ent
//   You can add the class to show or hide an element
// ------------------------------------------------------------------
.is-hide {
    display: none;
}

.is-show {
    display: block;
}