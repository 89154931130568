@import "variables/colour";
@import "elements/button";
@import "mixins/links";

.search-button {
    @extend .button--dark;
    margin: 0;
    text-transform: uppercase;
    float: left;
    text-align: center;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    @include list-link-colour--high-contrast($background: shade($grey, 50));
}