@import "variables/font";

// Rem
// rem(14px);
// rem(0 auto 1);
// rem(3%);
@function rem($values) {
  $font-size: $base-font-size;
  $rem-values: ();
  // Loop through the $values list
  @each $value in $values {
    // For each property value, if it's in rem or px, derive both rem and
    // px values for it and add those to the end of the appropriate buffer.
    // Ensure all pixel values are rounded to the nearest pixel.
    @if $value==0 or $value==0px {
      // 0 -- use it without a unit
      $rem-values: join($rem-values, 0);
    }
    @else if type-of($value)==number and not unitless($value) and (unit($value)==px) {
      // px value given - calculate rem value from font-size
      $new-rem-value: $value / $font-size;
      $rem-values: join($rem-values, #{$new-rem-value}rem);
    }
    @else if type-of($value)==number and not unitless($value) and ((unit($value)=="%") or (unit($value)==rem)) {
      // % or rem value given - don't add px or rem
      $rem-values: join($rem-values, #{$value});
    }
    @else if $value==auto {
      // auto - don't add px or rem
      $rem-values: join($rem-values, auto);
    }
    @else {
      // unitless value - use those directly as rem
      $rem-values: join($rem-values, #{$value}rem);
    }
  }

  @return $rem-values; 
}