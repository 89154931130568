@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/colour";
@import "functions/layout";
@import "functions/units";
@import "mixins/layout";
@import "mixins/icons";
@import "mixins/links";
@import "mixins/lists";
@import "mixins/text";

// Contact panel
.contact-panel {
    @include clearfix;
    @include no-bullets; // Remove bullets
    margin-bottom: rhythm(1/2);
    border: 1px solid $grey50;
    // Contact panel title
    a.contact-panel__title {
        display: block;
        background-color: $black5;
        position: relative;
        margin: 0;
        border-bottom: 1px solid $grey50;
        font-size: rem(14px);
        @include adjust-leading-to(0.9);
        padding: $space-s $space-m;
        @include list-link-colour;
        // Down triangle
        &:before,
        &:after {
            content: '';
            left: 50%;
            position: absolute;
            margin-left: -10px;
        }
        // Stroke
        &:before {
            bottom: -11px;
            @include triangle(20px, 10px, down, $grey50);
        }
        // Fill
        &:after {
            bottom: -10px;
            @include triangle(20px, 10px, down, $black5);
        }
        // Address
        span {
            display: block;
            font-weight: $bold;
            color: $black80;
        }
        &:focus,
        &:hover {
            background-color: $primary-colour;
            color: $primary-colour__text;
            &:after {
                bottom: -10px;
                @include triangle(20px, 10px, down, $primary-colour);
            }
            span {
                color: $primary-colour__text;
            }            
        }
        &:active {
            background-color: $secondary-colour;
            color: $secondary-colour__text;            
            &:after {
                bottom: -10px;
                @include triangle(20px, 10px, down, $secondary-colour);
            }
            span {
                color: $secondary-colour__text;
            }
        }
    }
    // Contact panel content
    .contact-panel__content {
        a {
            display: block;
            border-bottom: 1px solid $grey50;
            padding: rhythm(1/3) 1em;
            text-align: center;
            text-decoration: none;
            @include list-link-colour($background: $white);
        }
        > li {
            float: left;
            width: 50%;
            &:nth-child(2n+ 1) a {
                // Add dividers
                border-right: 1px solid $grey50;
            }
            &:last-child a,
            &:nth-last-child(2) a {
                // Remove the bottom borders
                border-bottom: none;
            }
        }
    }
}
