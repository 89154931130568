// ------------------------------------------------------------------
//   Responsive Intervals
// ------------------------------------------------------------------

$break-point-tablet-small:    600px !default;  // Small tablet - 600px
$break-point-tablet-portrait: 768px !default;  // Tablet portrait (e.g. Apple iPad, mini) - 768px
$break-point-tablet-landscape:1024px !default; // Tablet landscape (e.g. Apple iPad, mini) - 1024px
$break-point-wide-screen:     1200px !default; // Wide screen desktop - 1200px

$break-points: (
  tablet-small: 600px,
  tablet-port:  768px,
  tablet-land:  1024px,
  wide-screen:  1200px,
) !default;


// ------------------------------------------------------------------
//   defined spacing
// ------------------------------------------------------------------

$space-xx:  3rem;      //48px extra extra large spacing
$space-x:   2.25rem;   //36px  extra large spacing
$space-l:   1.5rem;    //24px  large spacing
$space-m:   1rem;      //16px  medium spacing
$space-s:   0.625rem;  //10px  small spacing
$space-xs:  0.3125rem; //5px   small spacing

// ------------------------------------------------------------------
//   Grid variables
// ------------------------------------------------------------------

$total-columns: 12;
$column-width: 6.5%;
$gutter-width: 2%;
$grid-padding: 0;
$container-width: 1200px;//75em; //1200px
$base-content-width: 94%;
$max-block-grid-items: 6;
$block-grid-density-increase-threshold: 7 !default;

// ------------------------------------------------------------------
//   Boxes
// ------------------------------------------------------------------

$block-margin-bottom:   48px; //rhythm(1.5);
$block-space-v:         36px; // box spacing top and bottom
$block-space-v-mobile:  24px; // box spacing top and bottom

// ------------------------------------------------------------------
//   Thumbnail images
// ------------------------------------------------------------------

$thumbnail-small:       85px; // Thumbnail small - 85px wide
$thumbnail-medium:      110px;  // Thumbnail medium - 110px wide
$thumbnail-large:       204px; // Thubmanil large - 204px