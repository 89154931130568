@import "variables/colour";
@import "mixins/images";
@import "mixins/layout";
@import "mixins/lists";

// Thumbnail grid - for Instagram
// The image width is set to 92px
.grid-thumbnail {
    @include clearfix;
    text-align: center;
    @include no-bullets; // Remove bullets
    li {
        display: inline-block;
        width: 92px;
        height: 92px;
        margin: 3px 3px -6px 3px; // Overwrite .main ul li
        a {
            @include image-clickable; // Hover status
            img {
                width: 100%;
                border: 1px solid $image-border-colour;
            }
        }
    }
}