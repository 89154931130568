@import "variables/layout";
@import "mixins/layout";

// ------------------------------------------------------------------
//   Website feedback button
// ------------------------------------------------------------------
.website-feedback {
    @include mq(tablet-small) {
        float: right;
        margin-top: $space-m;
    }
    @include mq(tablet-land) {
        margin: 0;
    }
    .button {
        margin: 0;
        width: 100%;
        margin-top: $space-s;
        @include mq(tablet-small) {
            width: auto;
            margin-top: 0;
        }
    }
}
