@import "variables/layout";
@import "variables/message";
@import "mixins/box";
@import "mixins/layout";
@import "mixins/text";

fieldset.form-section, 
.form-section fieldset{
    @extend %box;
    @extend %box__content;
    padding: $space-m;

    legend{
        @extend %box__header__title;
                
        box-sizing: content-box;
        float: left; /* Allows proper styling support */
        font-size: rem(20px);
        @include adjust-leading-to(0.75);
        padding: $space-s $space-m $space-s;
        margin: {
            top: -$space-m;
            bottom: $space-m;
            left: -$space-m;
        };

        + * {
            clear: left;
        }
    }

    > dl:last-child{
        margin-bottom: 0;
    }

    .form-actions{
        @extend %box__footer;

        padding-bottom: 0;
        margin-right: -$space-m;

        margin: {
            top: $space-m;
            left: -$space-m;
        };

        > *{
            margin-bottom: 0;
        }
    }
}

fieldset.form-section--full, 
.form-section--full fieldset{
    .form-actions{
        border-top: none;
    }
}

.form-section,
fieldset.form-section{
    @each $message-type, $message-style in $message-types{
        &--#{$message-type}{
            @include status-box(
                map-get($message-style, panel-header-colour), 
                map-get($message-style, panel-header-background-colour),
                'legend');
        }
    }    
}