@import "variables/colour";
@import "variables/border";
@import "variables/button";
@import "variables/colour";
@import "variables/font";
@import "variables/form";
@import "variables/layout";
@import "variables/message";
@import "functions/layout";
@import "functions/units";
@import "mixins/border";
@import "mixins/box";
@import "mixins/button";
@import "mixins/layout";
@import "elements/typography";

// ------------------------------------------------------------------
//   General box
// ------------------------------------------------------------------
.box {
    @include box;

    // ------------------------------------------------------------------
    //   Promotion Box
    // ------------------------------------------------------------------
    &--promotion {
        img {
            width: 100%;
        }
        .button {
            @include button($button-secondary...);
            text-align: center;
            width: 100% !important;
        }
        .link-list+ .module-button--border {
            margin-top: 0; // Remove extra margin top
        }
        .link-list {
            margin-bottom: (-$space-m);
            a {
                &:hover {
                    color: $black80;
                }
                &:focus {
                    background-color: $list-link-background-colour-focus;
                    color: $list-link-colour-focus;
                }
                &:active {
                    background-color: $list-link-background-colour-active;
                    color: $list-link-colour-active;
                }
            }
        }

        // ------------------------------------------------------------------
        //   Secondary Promotion Box
        // ------------------------------------------------------------------
        &--secondary {
            img {
                float: right;
                border: 1px solid $image-border-colour;
                margin-left: $space-m;
            }
            .link-list+ .module-button--border {
                margin-top: 0; // Remove extra margin top
            }
            .link-list {
                margin-bottom: (-$space-m);
                a {
                    &:hover {
                        color: $black80;
                    }
                    &:focus {
                        background-color: $list-link-background-colour-focus;
                        color: $list-link-colour-focus;
                    }
                    &:active {
                        background-color: $list-link-background-colour-active;
                        color: $list-link-colour-active;
                    }
                }
            }
        }
        &--link-colour-list {
            @include clearfix;
            margin-bottom: $block-margin-bottom / 2;
            img {
                display: none;
                float: left;
                width: rem(90px);
                @include mq(tablet-small) {
                    display: block;
                    width: rem(200px);
                }
                @include mq(tablet-land) {
                    float: none;
                    width: 100%;
                    margin-top: 0.5rem;
                    margin-bottom: 1rem;
                }
            }
            .link-list+ .module-button--border {
                margin-top: 0; // Remove extra margin top
                padding-top: 0;
            }
            &__inner {
                @include mq(tablet-small) {
                    margin-left: rem(200px);
                }
                @include mq(tablet-land) {
                    margin: 0;
                }
            }
        }

        // ------------------------------------------------------------------
        //   Tiled Promotion Box
        // ------------------------------------------------------------------        
        &--tile{
            .box__media ~ *{
                margin-left: 45%;
            }
            .box__media{
                width: 45%;
                height: 100%;
                position: absolute;
                overflow: hidden;
                background-color: $grey15;

                img{
                    position: absolute;
                    height: 100%;
                    max-width: inherit;
                    left: -9999px;
                    right: -9999px;
                    top: -9999px;
                    bottom: -9999px;
                    margin-bottom: auto !important;
                    margin: auto;
                }
            }

            .box__content,
            .box__footer{
                font-size: rem(13px);

                p{
                    font-size: rem(13px);
                }
            }

            .box__header{
                padding-left: $space-s;            
                padding-right: $space-s;    
            }        
            
            .box__content{
                padding: $space-xs $space-s;
            }

            .box__footer{
                box-shadow: none;
                border-top: none;
                padding: 0 $space-s $space-xs;

                a{
                    border-bottom: none;
                    text-decoration: none;
                    display: inline-block;
                    line-height: 1rem;
                }                
            }

            @include mq($max: tablet-small){
                .box__media ~ *{
                    margin-left: 0;
                }

                .box__media + *{
                    margin-top: rem(200px);
                }

                .box__media{
                    height: rem(200px);
                    width: 100%;
                }
            }
        }
    }

    // ------------------------------------------------------------------
    //   Secondary Box
    // ------------------------------------------------------------------
    &--secondary {
        @include box;
        background: $grey10;
    }

    // ------------------------------------------------------------------
    //   Tertiary Box
    // ------------------------------------------------------------------    
    &--tertiary {
        @include box;
        background: tint($grey, 84);
        border: none;
        box-shadow: none;
        margin-top: $space-m;
    }
    &--cta {
        @include box;
        background: tint($grey, 86);
        border: none;
        box-shadow: none;
        margin-top: $space-m;
        .box__header {
            padding: $space-m $space-m 0 $space-m;
        }
        .box__header__title {
            font-size: rem(25px);
        }
    }
    &--highlight {
        @include box;
        background: $yellow10;
        border-color: $yellow50;
        a {
            color: $black;
            border-bottom: 1px solid transparentize($black, 0.8);
        }
    }

    // Box header
    &__header {
        &--promotion {
            @extend .underline;
            background-color: $grey10;
            box-shadow: inset 0 0 3px $white;
        }
        // Supplementary box - white background with bottom border
        &--supplementary {
            @extend .box__header--promotion;
            background: $white;
            padding: $space-m $space-l;
        }
    }
    &__footer--secondary {
        // Not extending &__footer - problematic
        padding: $space-m;
        @include border-shaded(top);
        background-color: $grey10;
    }

    // ------------------------------------------------------------------
    //   Message Box Modifiers
    // ------------------------------------------------------------------    
    @each $message-type, $message-style in $message-types{
        &--#{$message-type}{
            @include status-box(
                map-get($message-style, panel-header-colour), 
                map-get($message-style, panel-header-background-colour)
            );
        }
    }    
}

// Secondary content column
.secondary-content {
    .box {
        .box__header {
            @include mq(tablet-land) {
                padding: rem(7px $space-s);
            }
        }
        .box__header__title {
            @include mq(tablet-land) {
                font-size: rem(18px);
            }
        }
        .box__content {
            @include mq(tablet-land) {
                padding: $space-s;
            }            
        }
        .box__footer {
            @include mq(tablet-land) {
                padding: $space-s;
            }
        }
    }
}