@import "variables/colour";
@import "functions/colour";
// Shaded lines
@mixin border-shaded($position) {
    border-#{$position}: 1px solid $black20;
    @if $position==left {
        box-shadow: inset 1px 0 0 $white;
    }
    @else if $position==right {
        box-shadow: 1px 0 0 $white;
    }
    @else if $position==top {
        box-shadow: inset 0 1px 0 $white;
    }
    @else {
        box-shadow: 0 1px 0 $white;
    }
}

@mixin border-shaded--high-contrast($position) {
    border-#{$position}: 1px solid $black;
    @if $position==left {
        box-shadow: inset 1px 0 0 $black70;
    }
    @else if $position==right {
        box-shadow: 1px 0 0 $black70;
    }
    @else if $position==top {
        box-shadow: inset 0 1px 0 $black70;
    }
    @else {
        box-shadow: 0 1px 0 $black70;
    }
}