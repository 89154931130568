@import "variables/colour";
@import "variables/font";
@import "variables/icon";
@import "variables/layout";
@import "functions/colour";
@import "functions/units";
@import "mixins/icons";
@import "mixins/layout";
@import "mixins/text";
@import "elements/icon";

// ------------------------------------------------------------------
//   Variables
// ------------------------------------------------------------------
$toggle-icon-height: 40px;

// ------------------------------------------------------------------
//   Toggle icons
//   Used in mobile view
// ------------------------------------------------------------------
.toggle-icon,
a.toggle-icon {
    // General styles
    position: relative;
    height: $toggle-icon-height;
    box-shadow: none;
    margin-bottom: 0;
    padding: $space-s $space-m;
    text-align: left;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .13);
    font-size: $button-font-size;
    display: inline-block;
    line-height: normal;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    cursor: pointer;
    font-family: $header-font-family;
    outline: none;
    -webkit-appearance: none; // Remove platform specific styles
    background-clip: padding-box; // Prevents bg colour from leaking outside the border
    border-radius: 3px; // Border radius
    user-select: none;
    @include mq(tablet-land) {
        // Hide when tablet landscape and above
        display: none;
    }
    &.curtin-icon-search {
        @include icon(search, $icon-set: $curtin-icon-set);

        height: rem(32px);
        padding: rem(5px 15px);
        span {
            display: none;
        }
        &.is-active{
            height: rem(40px);
        }
    }
    // Icons
    i {
        float: right;
        margin-right: -5px;
        &.curtin-icon-key {
            margin-top: -2px;
            font-size: rem(22px);
        }
    }
    &:hover {
        border-color: shade($black80, 30);
        box-shadow: none;
        box-shadow: inset 0 0 0 1px white(.1); // only apply inner glow
    }
    &.is-active {
        height: $toggle-icon-height+ 10;
        border-color: $grey20;
        color: $black60;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-image: linear-gradient($grey10, $grey20); // gradient overlay
    }

    // ------------------------------------------------------------------
    //   Search toggle icon
    //   Used in mobile view
    // ------------------------------------------------------------------
    &--search {
        background-color: $yellow;
        border: 1px solid #cb9900; // Overwrite link style
        color: $black80 !important;
        text-shadow: white(0.5) 0 1px 0;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45);
        background-image: linear-gradient(to top, #f1b500 0%, $yellow 48.24%, #ffc925 52.25%, #ffcf3f 100%);
        margin: 0;
        padding: $space-s 1.7em;
        text-align: center;
        width: auto;
        float: right;
        &:hover,
        &:focus {
            // Hover and focus status
            background-color: $yellow80;
            border-color: $yellow; // Overwrite link style
            box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45);
            background-image: linear-gradient(to top, #ffc925 0%, #ffc925 45.12%, #ffcf3f 49.19%, #ffd965 100%) 
        }
        &:active {
            // Active status
            outline-color: $white;
            color: $white;
            box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45);
            background: $grey20;
            border: 1px solid $grey20; // Overwrite link style
            background-image: linear-gradient(to top, #ffd14b 0%, #ffd965 41.5%, #ffd14b 54.88%, #ffc925 100%);            
        }
        &.is-active {
            background: $grey20;
            border: 1px solid $grey20;
            box-shadow: none;
        }
    }

    &--menu{
        @include icon(menu, $icon-set: $curtin-icon-set);
        
        display: table-cell;
        background: $white;
        margin-right: $space-m;
        border-radius: 0;
        border: none;
        box-shadow: none;
        color: $black;
        vertical-align: middle;
        padding: 0 $space-m;
        width: rem(20px);
        
        &::before{
            @include adjust-leading-to(0.9);

            float: none;
            margin: 0;
            font-size: rem(19px);

            @include mq(tablet-port) {
                font-size: rem(20px);
            }

            @include mq(tablet-land) {
                font-size: rem(24px);
            }
        }
        &.is-active{
            @include icon(close, $includeStyles: false, $icon-set: $curtin-icon-set);

            height: auto;
            color: $black;
            background: $white;
        }

        @include mq(tablet-land) {
            // Hide when tablet landscape and above
            display: none;
        }
    }

    // ------------------------------------------------------------------
    //   Login and navigation toggle icons
    //   Used in mobile view
    // ------------------------------------------------------------------
    &--login,
    &--navi {
        background-color: $black80;
        border: 1px solid $black; // Overwrite link style
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 1);
        color: $white;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .13);
        background-image: linear-gradient(to top, $black 0%, #191919 49.76%, #252525 53.76%, #333 100%);
        margin-right: 4%;
        float: left;
        width: 48%;
        &:hover,
        &:focus {
            // Hover and focus status
            background-color: $black70;
            border-color: $black ; // Overwrite link style
            color: $yellow ;
            box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .13);
            background-image: linear-gradient(to top, #0d0d0d 0%, #252525 49.76%, #333 53.76%, #3f3f3f 100%) ;
        }
        &:active {
            // Active status
            background-color: $black95;
            border-color: $black ; // Overwrite link style
            color: $white ; // Overwrite link style
            box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .13);
            background-image: linear-gradient(to top, #252525 0%, #252525 37.74%, #252525 47%, $black 100%) ;
        }
        &.is-active {
            border-color: $black;
            background: $black;
            color: $yellow;
            box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .13);
            background-image: linear-gradient(to top, #000 0%, #252525 69.24%, #252525 77%, #252525 100%);
        }
    }

    // ------------------------------------------------------------------
    //   Navigation toggle icons
    //   Used in mobile view
    // ------------------------------------------------------------------
    &--navi {
        margin-right: 0;
        border: 1px solid shade($primary-colour, 50);
        color: $header-colour;
        @include letterpress-black($opacity: 0.5);
        background-image: linear-gradient(to top, darken($primary-colour, 3%) 0%, $primary-colour 48.24%, lighten($primary-colour, 7%) 52.25%, lighten($primary-colour, 12%) 100%);
        &:hover,
        &:active,
        &:focus {
            border: 1px solid shade($primary-colour, 50);
            color: $header-colour;
            @include letterpress-black($opacity: 0.5);
            background-image: linear-gradient(to top, darken($primary-colour, 3%) 0%, $primary-colour 48.24%, lighten($primary-colour, 7%) 52.25%, lighten($primary-colour, 12%) 100%);
        }
        &.is-active {
            border-color: shade($primary-colour, 50);
            background: $black;
            color: $header-colour;
            background-image: linear-gradient(to top, darken($primary-colour, 3%) 0%, $primary-colour 48.24%, lighten($primary-colour, 7%) 52.25%, lighten($primary-colour, 12%) 100%);
        }
    }    
}
