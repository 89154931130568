@import "variables/colour";
@import "variables/layout";
@import "functions/units";
@import "mixins/border";
@import "mixins/layout";
@import "mixins/text";

.image {
    margin-bottom: 1.5rem;

    &-left,
    &-right {
        display: block;
        width: 100%;
        min-width: 110px; // Medium thumbnail size
        margin-bottom: 1em;
        margin-top: rem(8px);
        .box,
        .box--secondary {
            width: auto;
        }
    }

    &-left {
        float: left;
        @include mq(tablet-small) {
            margin-right: $space-l;
        }
    }

    &-right {
        float: right;
        @include mq(tablet-small) {
            margin-left: $space-l;
        }
    }

    &-portrait {
        @include mq(tablet-small) {
            width: 30%;
        }
    }

    &-landscape {
        @include mq(tablet-small) {
            width: 40%;
        }
    }

    // ------------------------------------------------------------------
    //   Full width image
    //   Fit image to parent div's width size
    // ------------------------------------------------------------------
    &--full {
        width: 100%;
    }

    &--caption {
        background: #efefef;
        color: $black75; 
        font-size: rem(14px);
        @include adjust-leading-to(0.8);
        padding: $space-s $space-m;
        @include border-shaded(bottom); 
    }        
}

picture {
    display: block;
    margin-top: $space-s;
}

// ------------------------------------------------------------------
//   Thumbnails for secondary content
// ------------------------------------------------------------------
.secondary-thumb {
    width: $thumbnail-small;
}