@import "variables/colour";
@import "variables/layout";
@import "functions/layout";
@import "mixins/text";
@import "components/list";

// ------------------------------------------------------------------
//   Contextual nav
//   A module for landing pages to link through to level 2 content
// ------------------------------------------------------------------
.contextual-nav {
    ul {
        margin: 0;
    }
    
    &{
        &__item {
            @include clearfix;
            margin: 0 !important;
            border-top: 1px solid $white;
            border-bottom: 1px solid $grey40;
            list-style: none;
            padding: $space-m;
            &:last-child {
                border-bottom: none !important;
            }
        }
        &__thumbnail {
            @extend .list__item__thumbnail;
            float: left;
            margin-left: 0;
            margin-top: 0;
            margin-right: $space-m;
        }
        &__title {
            @extend .list__item__title;
        }
        &__content {
            margin-left: rem($thumbnail-small)+$space-m;
            p {
                @include adjust-leading-to(0.9); // Line height
                font-size: rem(15px);
            }
        }
    }
}