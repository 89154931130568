@import "variables/colour";
@import "variables/layout";
@import "functions/colour";
// ------------------------------------------------------------------
// Toggle search in mobile layout (hidden in desktop layout)
@mixin toggle-content{
    display: none;
    background: $grey20; // Fallback
    padding: $space-m;
    background-image: linear-gradient($grey20, $grey35); // gradient overlay
}

.toggle-content {
    @include toggle-content;
}