@import "variables/colour";
@import "variables/input";
@import "variables/layout";
@import "functions/layout";
@import "mixins/text";
@import "mixins/layout";
@import "mixins/icons";

// ------------------------------------------------------------------
//   Video container
// ------------------------------------------------------------------
.video-container {
    @include clearfix;
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    /* 16:9 */
    background-color: $black;
    margin-top: $space-s;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// ------------------------------------------------------------------
//   Video close button
// ------------------------------------------------------------------
.video-player {
    display: none;
    margin-top: rhythm(0.5);
    .js-close-video {
        display: block;
        position: relative;
        width: 100%;
        padding-top: rhythm(0.5);
        padding-bottom: rhythm(0.5);
        background-color: $yellow;
        color: $header-font-colour;
        font-family: $button-font-family;
        font-weight: $bold;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        font-size: rem(18px);
        @include letterpress-white(0.5); // text drop shadow
        // Arrow
        i {
            &:before {
                position: relative;
                @include arrow( $wide: true, $direction: left, $position: static);
                // Overwrite margins
                margin-top: 0;
                margin-right: 1.25em;
                margin-left: 0;
                top: 2px;
            }
        }
        // Hover
        &:hover {
            cursor: pointer;
            background-color: $white;
        }
    }
}