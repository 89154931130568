@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/colour";
@import "functions/layout";
@import "functions/units";
@import "mixins/icons";
@import "mixins/layout";

// ------------------------------------------------------------------
//   Standard featured box - for homepage etc
// ------------------------------------------------------------------
.featured-box {
    position: relative;
    
    // ------------------------------------------------------------------
    //  Feature box components
    // ------------------------------------------------------------------    
    & {
        // Image or video
        &__media {
            position: relative;
            img {
                // Feature image
                width: 100%;
            }
        }
        // Title
        &__title {
            display: block;
            position: relative;
            background-color: $primary-colour;
            color: $header-colour;
            margin: 0;
            font-family: $header-font-family;
            font-weight: $header-font-weight;
            @include adjust-leading-to(0.8); // added line-height
            @include letterpress-white(0.4); // _mixin.scss
            font-size: rem(20px); // _mixin.scss
            padding: $space-s $space-m;
            @include mq(tablet-small) {
                font-size: rem(22px);
            }
            @include mq(tablet-land) {
                padding: $space-m;
            }
            @include mq(wide-screen) {
                padding: $space-m $space-l;
            }
            a {
                display: block;
                margin: 0;
                border: none;
                color: $header-colour;
                padding: $space-m;
                margin: (-$space-m); // Apply the negative margins to remove extra spaces
                @include mq(tablet-land) {
                    padding-left: $space-l; // larger padding for screens at landscape tablet and up
                    padding-right: $space-l; // larger padding for screens at landscape tablet and up
                    margin-left: (-$space-l); // Apply the negative margins to remove extra spaces
                    margin-right: (-$space-l); // Apply the negative margins to remove extra spaces
                }
                // Right arrow
                &:after {
                    @include arrow($wide: true);
                }
            }
        }
        // Content
        &__content {
            @include clearfix;
            position: relative;
            overflow: hidden; // For full height columns
            background-color: $black80;
            color: $white;
            padding: $space-m;
            @include mq(tablet-land) {
                padding-left: $space-l; // larger padding for screens at landscape tablet and up
                padding-right: $space-l; // larger padding for screens at landscape tablet and up
            }
            p {
                margin-bottom: 0; // paragraphs
                font-size: rem($base-font-size+ 2px);
            }
            a {
                @include link-colour--high-contrast;
            }

            &--primary {
                img {
                    border: 1px solid $grey90;
                }
                @include mq(tablet-land) {
                    float: left;
                    width: 66%;
                }
            }
            &--secondary {
                // Event date
                .event-date {
                    font-family: $header-font-family;
                    font-weight: $header-font-weight;
                }
                .event-date__day,
                .event-date__date {
                    text-transform: uppercase;
                    color: $yellow;
                }
                .event-date__year {
                    font-size: rem(30px);
                    margin-top: 0.5rem;
                }
                @include mq(tablet-land) {
                    width: 30%;
                    padding-left: 4%;
                    float: right;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }
            }            
        }
        // Info
        &__info {
            @extend .featured-box__content;
            color: $black20;
            @include border-shaded--high-contrast(top);
            padding: $space-s $space-m;
            @include mq(tablet-land) {
                padding: $space-s $space-l;
                p {
                    font-size: rem(14px);
                }
                .article__author {
                    @include border-shaded--high-contrast(right);
                    padding-right: rem(8px);
                }
                .article__pubdate {
                    @include border-shaded--high-contrast(right);
                    padding-right: rem(8px);
                }
            }
        }
    }    
}