@import "variables/border";
@import "variables/colour";
@import "variables/input";
@import "variables/layout";
@import "functions/units";

input,
button,
textarea {
    vertical-align: baseline;
    *vertical-align: middle;
    font-size: rem(14px);
}

#{$text-selector},
button,
textarea {
    display: inline-block;
    border: 1px solid $input-border-colour;
    background-color: $input-background;
    color: $text-colour;
    font-family: $base-font-family;
    outline: none;
    vertical-align: middle;
    line-height: normal;
    box-sizing: border-box;
    margin-bottom: rem(12px);
    padding: $space-s;
}

.form-group,
.form-group--full {
    #{$text-selector},
    button,
    textarea {
        margin-bottom: 0;
    }
}

.form-group--full,
fieldset.form-section--full,
.form-section--full fieldset{
    #{$text-selector},
    button,
    textarea {
        width: 100%;
    }
}

.radio-stacked,
.checkbox-stacked,
.radio-inline,
.checkbox-inline,
.form-group .radio-stacked,
.form-group .radio-inline,
.form-group .checkbox-stacked,
.form-group .checkbox-inline,
fieldset .radio-stacked,
fieldset .radio-inline,
fieldset .checkbox-stacked,
fieldset .checkbox-inline {
    label {
        cursor: pointer;
        font-weight: $medium;
        line-height: rem(23px);
        padding-left: rem(20px);
    }
    
    input[type="radio"], input[type="checkbox"]{
        float: left;
        display: block;
        margin-top: rem(4px);
    }
}

.radio-stacked,
.checkbox-stacked,
.form-group .radio-stacked,
.form-group .checkbox-stacked,
fieldset .radio-stacked,
fieldset .checkbox-stacked {
    display: block;
    width: 100%;
    border-radius: $border-radius;
    margin-bottom: rem(5px);
    padding: rem(3px);
    &:hover {
        background-color: $black10;
    }
}

.radio-inline,
.checkbox-inline,
.form-group .radio-inline,
.form-group .checkbox-inline,
fieldset .radio-inline,
fieldset .checkbox-inline {
    display: inline-block;
    margin-right: rem(11px);
    padding: rem(5px);
}

.with-icon {
    &::before {
        position: absolute;
        padding: $space-s;
        font-size: 1.25rem;
    }

    input,
    textarea {
        padding-left: $space-x;
    }
}
