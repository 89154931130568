// ------------------------------------------------------------------
//   Turns off the bullets for an entire list and remove the margin bottom
//
//   Syntax:
//   .list {
//     @include no-bullets;
//   }
// ------------------------------------------------------------------

@mixin no-bullets {
  list-style: none;
  margin-bottom: 0; // Overwrite the default margin

  li {
    @include no-bullet;
  }
}

@mixin no-bullet {
  list-style-image : none;
  list-style-type  : none;
  margin-left      : 0;
}