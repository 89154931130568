@import "variables/border";
@import "functions/units";
@import "mixins/images";
@import "mixins/lists";
@import "mixins/layout";

// Image list
.image-grid-list {
    @include clearfix;
    font-size: 0 !important;
    margin-left: -2.5%;
    @include no-bullets;
    li.grid-list__item {
        display: inline-block;
        margin: 0 0 2.5% 2.5%;
        width: 47.5%;
        vertical-align: top;
        @include mq(tablet-small) {
            width: 30.83%;
        }
        @include mq(tablet-port) {
            width: 22.5%;
        }
        @include mq(tablet-land) {
            width: 17.5%;
        }
    }
    a {
        @include image-clickable; // Hover status
        img {
            width: 100%;
            border: 1px solid $image-border-colour;
        }
    }
}
