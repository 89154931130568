@import "variables/animation";
@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/colour";
@import "functions/layout";
@import "functions/units";
@import "mixins/border";
@import "mixins/links";
@import "mixins/text";
@import "mixins/layout";

// ------------------------------------------------------------------
//   Headings
// ------------------------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6,
.heading {
    display: block;
    margin: 0;
    color: $black; //$header-font-colour;
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    text-rendering: optimizelegibility;
    a {
        color: $header-font-colour;
        text-decoration: none;
        &:hover {
            color: darken($header-font-colour, 10%);
            text-decoration: none;
        }
    }
}

// ------------------------------------------------------------------
// Module
// Removes default heading padding-top to keep spacing consistent
.module {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .heading {
        padding-top: 0;
    }
}

.heading {
    padding: 0;
}

h1,
.h1 {
    font-family: $header-font-family;
    margin-bottom: 1.2rem;
    @include adjust-leading-to(0.7);
    font-size: rem(30px);
    @include mq(tablet-small) {
        font-size: rem(28px);
    }
    @include mq(tablet-port) {
        letter-spacing: -0.2px;
        font-size: rem(34px);
    }
}

h2,
.h2 {
    font-family: $base-font-family;
    font-weight: $medium;
    font-size: rem(22px);
    @include adjust-leading-to(0.75);
    margin-bottom: rem(14px);
    @include mq(tablet-port) {
        letter-spacing: -0.2px;
        font-size: rem(28px);
    }
    &+h3 {
        margin-top: $space-s; // adjusts top-margin of <h3> when sitting directly under <h2>
    }
}

// If H2 is directly after a <p>, <li>, <div>, <img>, <table>
p,
li,
div,
img,
table {
    +h2 {
        margin-top: $space-l;
    }
}

h3,
.h3 {
    font-family: $base-font-family;
    font-weight: $regular;
    font-size: rem(20px);
    @include adjust-leading-to(0.75);
    margin-bottom: 0.5rem;
    @include mq(tablet-port) {
        letter-spacing: -0.5px;
        font-size: rem(24px);
    }
    &+h4 {
        margin-top: $space-s; // adjusts top-margin of <h4> when sitting directly under <h3>
    }
}

// If H3 is directly after a <p>, <li>, <div>, <img>, <table>
p,
li,
div,
img,
table {
    +h3 {
        margin-top: $space-m;
    }
}

h4,
.h4 {
    font-family: $base-font-family;
    font-weight: $medium;
    font-size: rem(18px);
    @include adjust-leading-to(0.72);
    margin-bottom: 0.5rem;
    @include mq(tablet-port) {
        letter-spacing: -0.4px;
        font-size: rem(20px);
    }
    &+h5 {
        margin-top: 0; // adjusts top-margin of <h5> when sitting directly under <h4>
    }
}

// If H4 is directly after a <p>, <li>, <div>, <img>, <table>
p,
li,
div,
img,
table {
    +h4 {
        margin-top: $space-m;
    }
}

h5,
.h5 {
    font-family: $base-font-family;
    font-weight: $medium;
    font-size: rem(17px);
    @include adjust-leading-to(0.8);
    margin-bottom: 0.5rem;
    @include mq(tablet-port) {
        letter-spacing: -0.3px;
        font-size: rem(18px);
        &+h6 {
            margin-top: 0; // adjusts top-margin of <h6> when sitting directly under <h5>
        }
    }
}

// If H5 is directly after a <p>, <li>, <div>, <img>, <table>
p,
li,
div,
img,
table {
    +h5 {
        margin-top: $space-m;
    }
}

h6,
.h6 {
    font-family: $base-font-family;
    font-weight: $bold;
    color: $black80;
    font-size: rem(16px);
    @include adjust-leading-to(0.9);
    margin-bottom: 0.5rem;
    @include mq(tablet-port) {
        font-size: rem(16px);
    }
}

// If H6 is directly after a <p>, <li>, <div>, <img>, <table>
p,
li,
div,
img,
table {
    +h6 {
        margin-top: $space-m;
    }
}

.underline {
    margin-bottom: $space-m;
    @include border-shaded(bottom);
}

// ------------------------------------------------------------------
//   Page title
// ------------------------------------------------------------------
.page-title {
    @extend .heading;
    margin-bottom: rem(12px);
    @include mq(tablet-small) {
        margin-bottom: rem(20px);
    }
    // Page titles with underline
    &.underline {
        padding-bottom: rem(16px);
        @include border-shaded(bottom);
    }
    // Intro paragraph directly after .page-title
    +p {
        font-family: $header-font-family;
        font-size: rem(20px);
    }
}

// ------------------------------------------------------------------
//   Text
// ------------------------------------------------------------------
.intro-block {
    position: relative;
}

.intro {
    font-size: rem(18px);
    @include mq(tablet-port) {
        font-size: rem(20px);
    }
}

p,
pre {
    font-size: rem(16px);
    @include adjust-leading-to(0.92);
    margin: 0 0 $space-s 0;
    @include mq(tablet-port) {
        font-size: rem(17px); // Set the content font size to 17px
        margin: rem(0 0 12px 0);
    }
}

label{
    vertical-align: baseline;
    *vertical-align: middle;    
    font-size: rem(16px);
    @include adjust-leading-to(0.92);
    display: block;
    margin: 0;
    font-weight: $bold;

    &[for]{
        cursor: pointer;
    }
}

.module {
    p,
    pre,
    label {
        margin: rem(0 0 8px 0);
    }
}

a {
    @include link-colour;
    transition: all $transition-speed $transition-style;

    &:hover,
    &:active,
    &:focus {
        outline: none; // Remove the dotted outline
    }
}

// ------------------------------------------------------------------
//   Content divider
// ------------------------------------------------------------------
hr {
    border: none;
    height: 1px;
    background: $grey40;
    margin: 1rem 0;
    @include border-shaded(top);
    &+h2,
    &+h3,
    &+h4,
    &+h5,
    &+h6 {
        padding-top: 0;
    }
}

cite {
    font-weight: $medium;
    font-size: rem(17px);
}

// ------------------------------------------------------------------
//   Blockquote
// ------------------------------------------------------------------
blockquote,
.blockquote {
    border-left: 3px solid $grey35;
    color: $black95;
    margin: $space-l 0;
    padding: 0 $space-m;
    @include mq(tablet-land) {
        padding: 0 $space-x 0 $space-l;
    }
    p {
        font-size: rem(18px);
        @include adjust-leading-to(1);
        &:last-child {
            margin-bottom: 0;
        }
    }
    cite {
        font-size: rem(17px);
    }
}

.blockquote--module {
    @extend .blockquote;
    border: none;
    padding: 0;
    margin: 0;
    @include adjust-leading-to(0.85); // for content not within p tags
    font-size: rem(15px); // for content not within p tags
    @include mq(tablet-land) {
        font-size: rem(14px); // for content not within p tags
    }
    img {
        float: right;
        margin: 0 0 .5em .5em;
        border: 1px solid #ffffff;
    }
    footer {
        margin-top: rem($space-m);
    }
    cite {
        font-weight: $bold;
        font-size: rem(16px); // compass import
        span {
            display: block;
            font-weight: $regular;
        }
    }
}

// ------------------------------------------------------------------
//   Pull quote
// ------------------------------------------------------------------
.pullquote {
    margin: 0 0 rhythm(0.5, $base-font-size) 0;
    color: $black95;
    border-left: 3px solid $grey35;
    padding-left: rem($space-m);
    p {
        font-size: rem(18px); // compass import
        @include adjust-leading-to(1);
        &:last-child {
            margin-bottom: 0;
        }
    }
    i {
        display: block;
        margin-top: rhythm(0.5, $base-font-size);
        color: $black80;
        font-style: normal;
        font-weight: normal;
        font-size : rem($base-font-size);
        @include mq(tablet-land) {
            text-align: right;
        }
    }
    @include mq(tablet-land) {
        width: 50%;
        float: right;
        margin-left: 1.5rem;
    }
}