@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/colour";
@import "functions/units";
@import "mixins/border";
@import "mixins/layout";
@import "mixins/links";
@import "mixins/lists";
@import "mixins/text";

// ------------------------------------------------------------------
// To populate the faculty specific list colours for .list--colour-border
// ------------------------------------------------------------------
@mixin list-item-faculty-styles {
    $faculties: (
        sci-eng: $faculty-sci-eng,
        cas: $faculty-cas,
        hum: $faculty-hum,
        hea-sci: $faculty-hea-sci,
        cbs: $faculty-cbs        
    );
    @each $faculty, $colour in $faculties {
        .list__item--#{$faculty} {
            border-left: .75em solid $colour;
        }
    }
}

// ------------------------------------------------------------------
//   Standard list (dashed underline)
// ------------------------------------------------------------------
.list {
    @include clearfix;
    @include no-bullets; // removes default bullet style

    &{
        // ------------------------------------------------------------------
        // List item
        // ------------------------------------------------------------------
        &__item {
            @include clearfix;
            border-bottom: 1px dashed $grey60;
            margin: 0 !important;
            padding-top: $space-m;
            padding-bottom: $space-m;
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
            &.list__item-no-thumbnail {
                .list__item__content {
                    @include last;
                }
            }

            &__title {
                font-family: $base-font-family;
                text-transform: none;
                font-size: rem(18px);
                font-weight: normal;
                @include adjust-leading-to(0.8);
                @include mq(tablet-port) {
                    font-size: rem(19px);
                }
                a {
                    @include link-colour( $border: false);
                }
            }

            &__time {
                //@extend .event-list__item__head__meta; //TODO (MS-2016-10-03): create rule
                margin-bottom: 0.5rem;
                .dtstart {
                    padding-right: 0.75rem;
                }
                .multiple-dates {
                    border-left: 1px solid $black20;
                    padding-left: 1rem;
                    i {
                        position: relative;
                        top: 2px; // Fix positioning
                        margin-left: 0.75rem;
                        color: $olive;
                    }
                }
                a {
                    @include link-colour($border: false);
                }
            }

            // ------------------------------------------------------------------
            // List item content
            // ------------------------------------------------------------------            
            &__content {
                margin-right: $thumbnail-small+ $base-font-size;
                a {
                    @include link-colour;
                }
                &.no-link-border a {
                    @include link-colour($border: false);
                }
                > ul {
                    // For unordered lists within list items
                    li {
                        list-style-type: disc;
                        // Reducing spacing because of smaller font size
                        margin-top: $space-xs;
                        margin-bottom: $space-xs;
                        margin-left: $space-l;
                    }
                }
            }

            // ------------------------------------------------------------------
            // List item - small thumbnails
            // ------------------------------------------------------------------
            &__thumbnail {
                width: $thumbnail-small;
                float: right;
                margin-top: rem(5px);
                img {
                    display: block;
                    border: 1px solid $image-border-colour;
                    width: 100%;
                }
                a {
                    @include image-clickable;
                }
            }                               
        } 
    }

    &--solid-border {
        @include border-shaded(bottom);
        &--hc {
            @include border-shaded--high-contrast(bottom);
        }
    }
    // ------------------------------------------------------------------
    // List item - medium thumbnails
    // ------------------------------------------------------------------
    &--medium-thumbnail {
        .list__item__thumbnail {
            width: $thumbnail-medium;
        }
        .list__item__content {
            margin-right: $thumbnail-medium+ $base-font-size;
        }
    }
    // ------------------------------------------------------------------
    // List item - large thumbnails
    // ------------------------------------------------------------------    
    &--large-thumbnail {
        @include clearfix;
        @include no-bullets; // removes default bullet style
        .list__item__thumbnail {
            width: 100%;
            margin-bottom: 1rem;
            width: $thumbnail-small;
            margin: 0;
            @include mq(tablet-port) {
                width: $thumbnail-large;
            }
        }
        .list__item__heading {
            margin-right: $thumbnail-small+ $base-font-size;
            @include mq(tablet-port) {
                margin-right: $thumbnail-medium+ $base-font-size;
            }
            @include mq(wide-screen) {
                margin-right: $thumbnail-large+ 25.5px;
            }
            margin-bottom: rhythm(1/4);
            a {
                @include link-colour--high-contrast( $border: false);
            }
        }
        .list__item__title {
            margin-right: $thumbnail-small+ $base-font-size;
            @include mq(tablet-port) {
                margin-right: $thumbnail-medium+ $base-font-size;
            }
            @include mq(wide-screen) {
                margin-right: $thumbnail-large+ 25.5px;
            }
        }
        .list__item__content {
            margin-right: $thumbnail-small+ $base-font-size;
            @include mq(tablet-port) {
                margin-right: $thumbnail-medium+ $base-font-size;
            }
            @include mq(wide-screen) {
                margin-right: $thumbnail-large+ 25.5px;
            }
        }
    }
    
    // ------------------------------------------------------------------
    // Right thumbnail
    // ..................................................................
    &--thumbnail-right {
        .list__item__thumbnail {
            float: right;
            margin-left: $space-m;
        }
        .list__item__content {
            margin-left: 0;
            margin-right: $thumbnail-small+ $base-font-size;
        }
        &--medium-thumbnail {
            .list__item__content {
                margin-right: $thumbnail-medium+ $base-font-size;
            }
        }
    }
    // ------------------------------------------------------------------    
    // Left thumbnail
    // ------------------------------------------------------------------
    &--thumbnail-left {
        .list__item__thumbnail {
            float: left;
            margin-right: $space-m;
        }
        .list__item__content {
            margin-right: 0;
            margin-left: $thumbnail-small+ $base-font-size;
        }
        &--medium-thumbnail {
            .list__item__content {
                margin-left: $thumbnail-medium+ $base-font-size;
            }
        }
    }
    // No thumbnails
    &--no-thumbnail {
        .list__item__thumbnail {
            display: none;
        }
        .list__item__content {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }
    // List
    &--box {
        .list__item {
            border-bottom: 1px solid $grey60;
            padding: 0.75rem;
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .list__item__title {
            margin-bottom: 0;
            margin-left: $thumbnail-small+ $base-font-size;
            padding-bottom: rhythm(1/4, 16px);
            @include mq(wide-screen) {
                margin-left: $thumbnail-small+ 25.5px;
            }
        }
        .list__item__content {
            margin-left: $thumbnail-small+ $base-font-size;
            @include mq(wide-screen) {
                margin-left: $thumbnail-small+ 25.5px;
            }
        }
    }
    // List box no thumbnail
    &--box-no-thumbnail {
        @include clearfix;
        @include no-bullets; // removes default bullet style
        li {
            list-style: none;
            border-top: 1px solid $white;
            border-bottom: 1px solid $grey40;
            padding-top: rhythm(1/3, 15px);
            padding-bottom: rhythm(1/3, 15px);
            padding-left: 1rem;
            padding-right: 1rem;
            @include mq(wide-screen) {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
            &:last-child {
                border-bottom: none;
            }
        }
        a {
            @include link-colour($border: false);
        }
        .list__item__title {
            color: shade($grey, 45);
            font-weight: $semi-bold;
            font-size: rem(18px);
        }
    }
    &--box--hc {
        @extend .list--box;
        .list__item {
            border-bottom: 1px solid $black;
            border-top: 1px solid $black75;
            &:last-child {
                border-bottom: none;
            }
        }
    }
    // Coloured left hand border
    &--colour-border {
        .list__item {
            border-bottom: 1px solid $grey60;
            padding: 0.75rem;
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &:last-child {
                border-bottom: none;
            }
        }
        .list__item__title {
            margin-bottom: 0;
            padding: 0;
            @include adjust-leading-to(1);
        }
        .list__item__sub-title {
            font-family: $base-font-family;
            font-weight: 600;
            text-transform: none;
            @include adjust-leading-to(1);
            font-size: rem(16px);
            margin-bottom: 0.25rem;
        }
        a {
            @include link-colour( $border: false);
        }
        @include list-item-faculty-styles;
    }    
}

// List high contrast
.list-hc {
    .list__item {
        background: $black80;
        color: $grey35;
        padding: 1rem;
        @include mq(tablet-port) {
            padding: 1.5rem;
        }
    }
}

.list-description--inline {
    margin-bottom: $space-xs;
    dt,
    dd {
        display: inline;
        margin: 0;
    }
    dd {
        &+dd {
            &:before {
                content: ", ";
            }
        }
    }
    span {
        display: block;
        margin: $space-xs 0;
    }
}

// ------------------------------------------------------------------
// Special contexts
// ------------------------------------------------------------------

// ------------------------------------------------------------------
// List item title within secondary content of standard pages
// ------------------------------------------------------------------
.secondary-content .list__item__title {
    font-size: rem(17px);
    @include mq(tablet-land) {
        font-size: rem(15px);
        margin-bottom: $space-xs;
    }
}

// ------------------------------------------------------------------
// List item title for condensed lists (no .list__item__content)
// ------------------------------------------------------------------
.condensed .list__item__title {
    font-size: rem(17px);
    @include mq(tablet-land) {
        font-size: rem(15px);
    }
}

@at-root{
    .page-home .list__item__content {
        p {
            font-size: rem(15px);
        }
    }
}

.main-content .list__item__content {
    p,
    li {
        font-size: rem(15px);
        @include mq(tablet-land) {
            font-size: rem(16px);
        }
    }
}
.secondary-content .list__item__content {
    p {
        font-size: rem(15px);
        @include mq(tablet-land) {
            font-size: rem(14px);
        }
    }
}


// ------------------------------------------------------------------
// Secondary content
// ------------------------------------------------------------------
.secondary-content .list__item {
    @include mq(tablet-land) {
        padding-top: $space-xs;
        padding-bottom: $space-xs;
        &:first-child {
            padding-top: 0; // Remove top padding on the first child
        }
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}

// ------------------------------------------------------------------
// List item title for condensed lists (no .list__item__content)
// ------------------------------------------------------------------
.condensed .list__item {
    padding-top: $space-s;
    padding-bottom: $space-s;
    &:first-child {
        padding-top: 0; // Remove top padding on the first child
    }
    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
}