@import "variables/button";
@import "variables/colour";
@import "variables/input";
@import "variables/font";
@import "variables/layout";
@import "functions/units";
@import "mixins/border";
@import "mixins/layout";
@import "templates/action-input";

// ------------------------------------------------------------------
//   Default template for buttons
// ------------------------------------------------------------------
%button {
    @extend %action-input;
    display: inline-block;
    text-align: center;
    padding: $space-s $space-m;
    margin: $space-xs 0 0;
    @include mq(tablet-small) {
        // Normal width on small tablet and above
        width: auto;
        margin-top: 0;
    }
    // For split button - associated information
    & .button__meta {
        display: inline;
        border-left: 1px solid shade($grey, 60);
        @include border-shaded (left);
        padding-left: $space-xs;
        margin-left: $space-xs;
    }
}