@import "functions/layout";
@import "mixins/images";
@import "mixins/lists";
@import "mixins/layout";

// Video list
.video-grid-list {
    @include clearfix;
    @include no-bullets;
    li.video-grid-list__item {
        margin-bottom: rhythm(1);
        @include mq(tablet-land) {
            float: left;
            width: 48%;
            margin: 0 4% rhythm(1) 0;
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
    .video-title {
        height: 3em;
        margin-top: rhythm(0.5);
        overflow: hidden;
    }
}