@import "variables/layout";
@import "mixins/layout";
.cf,
.row {
    @include clearfix;
}

.wrapper {
    @include clearfix;
    width: $base-content-width;
    max-width: $container-width;
    padding: {
        left: 0;
        right: 0;
    }
    margin: {
        left: auto;
        right: auto;
    }
}

// Last item in row
// ------------------------------------------------------------------
.last {
    @include last;
}

// For centring everything
// ------------------------------------------------------------------
.centre {
    text-align: center;
}

// ------------------------------------------------------------------
//   Right hand content section in standard pages
// ------------------------------------------------------------------
.secondary-content {
    @include columns(3);
    @include last;
    margin-top: $space-l;
}

//$columns, $total-columns: $total-columns, $column-width: $column-width, $gutter-width: $gutter-width) {
@for $i from 1 through $total-columns {
    .col-#{$i} {
        @include columns($i);
        @include mq(wide-screen) {
            // Resets default grid with a new grid (new widths and gutters) just for home/landing pages
            @at-root {
                .page-home .col-#{$i} {
                    @include span-columns($i, 12, 4.5%, 4%);
                }
            }
        }
        @for $j from 1 through $i {
            .col-#{$j} {
                @include columns($j, $i);
                @include mq(wide-screen) {
                    // Resets default grid with a new grid (new widths and gutters) just for home/landing pages
                    @at-root {
                        .page-home .col-#{$j} {
                            @include span-columns($j, 12, 4.5%, 4%);
                        }
                    }
                }
            }
        }
    }
}

// ------------------------------------------------------------------
// ONLY to be used wihtin .col-9 for true 50/50 split
// ------------------------------------------------------------------
.col-4-5 {
    @include span-columns(12, 12, 5.87%, 2.69%);
    @include mq(tablet-land) {
        @include span-columns(6);
    }
}