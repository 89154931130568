@import "variables/colour";

// ------------------------------------------------------------------
//   Font configuration
// ------------------------------------------------------------------

$font-unit: 1em !default;


// ------------------------------------------------------------------
//   Text positioning
// ------------------------------------------------------------------

$min-line-padding: 2px !default;


// ------------------------------------------------------------------
//   Fonts
// ------------------------------------------------------------------

$font-sans-serif:       Helvetica, Arial, sans-serif !default; //"Helvetica Neue"


// ------------------------------------------------------------------
//   Font weights
// ------------------------------------------------------------------

$light:                 100;
$regular:               300;
$medium:                400;
$semi-bold:             500;
$bold:                  bold; //600;


// ------------------------------------------------------------------
//   Base fonts
// ------------------------------------------------------------------

$base-font-family:      $font-sans-serif !default;
$base-font-weight:      $medium !default;
$base-font-size:        16px !default;
$base-line-height:      27px !default;


// ------------------------------------------------------------------
//   Headings
// ------------------------------------------------------------------

$header-font-family:    "SansaSoft", Calibri, sans-serif !default;
$header-font-weight:    $regular !default;
$header-font-colour:    $black70 !default;
$header-line-height:    1.1 !default;
