@import "variables/button";
@import "variables/colour";
@import "variables/layout";
@import "mixins/layout";
@import "mixins/icons";
@import "mixins/text";

// ------------------------------------------------------------------
//   Standard pagination
//   Note:  Carousel pagination shares the same styles
// ------------------------------------------------------------------
.pagination {
    @include clearfix;;
    @include border-shaded(top);
    margin-top: $space-m;
    padding-top: $space-m;
}

.pagination ul,
.owl-pagination {
    text-align: center;
    li,
    .owl-page {
        display: inline;
        margin: 0 .3125em 0 0;
        font-family: $button-font-family;
        // Link
        a,
        .owl-numbers {
            @include button;
            padding: rhythm(.4, 15px) rhythm(.6, 15px);
            display: inline;
            text-align: center;            
            @include adjust-font-size-to(14px, 0.6);
        }
        &.is-active a,
        &.current a,
        &.is-active .owl-numbers {
            // Current status
            background: white;
            color: $text-colour;
            box-shadow: none;
            cursor: auto;
            &:hover,
            &:focus {
                border-color: $grey80;
            }
        }
        // Navigation
        &.page-prev a,
        &.page-next a {
            position: relative;
            text-transform: uppercase;
            letter-spacing: 1px;
            line-height: 35px; // Vertical centering
        }
        // Prev page
        &.page-prev a {
            padding-left: rem(42px);
            &:before {
                @include arrow( $position: absolute, $direction: left); // _mixin.scss
                left: rem(-42px);
                margin-top: -6px; // Adjust the arrow position
            }
        }
        // Next page
        &.page-next a {
            padding-right: rem(42px);
            &:before {
                @include arrow( $position: absolute); // _mixin.scss
                margin-top: -6px; // Adjust the arrow position
            }
        }
    }
}

// Only for page navigation
.pagination ul li {
    display: none; // Hide pagination
    @include mq(tablet-small) {
        display: inline-block; // Display pagination on tablet small or above
    }
    // Only show 'Prev' and 'Next' on small screen device
    &.page-prev,
    &.page-next {
        display: inline-block;
    }
}

// Standard pagination align left
.pagination--left {
    float: left;
}

// Standard pagination align right
.pagination--right {
    float: right;
}

// ------------------------------------------------------------------
//   Pagination divider
// ------------------------------------------------------------------
.pagination-divider {
    border-top: 1px solid $black20;
    padding-top: 2rem;
    margin-top: 1rem;
}