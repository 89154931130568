@import "variables/border";
@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/layout";
@import "functions/units";
@import "mixins/border";
@import "mixins/layout";
@import "mixins/text";

// ------------------------------------------------------------------
//   Default template for boxes
// ------------------------------------------------------------------
%box {
    position: relative;
    border: 1px solid $box-border-colour;
    background-color: $white;
    box-shadow: $box-shadow-x $box-shadow-y $box-shadow-blur $box-shadow-colour;

    &.flat {
        box-shadow: none;
    }
    &.flat--high-contrast {
        box-shadow: none;
        border: 1px solid $black;
        background: $black85;
    }

    // Box header
    &__header {
        padding: $space-s $space-m 0 $space-m;
        &.underline {
            margin-bottom: 0;
            padding-bottom: $space-s;
        }
        // Down arrow
        &.arrow-down {
            &:after {
                content: '';
                left: 1rem;
                bottom: -8px;
                position: absolute;
                z-index: 100;
            }
        }
        &.no-padding {
            padding: 0;
        }
        p {
            margin-top: rhythm(1/2);
            margin-bottom: 0; // Remove bottom margin of paragraph
        }

        &__title {
            margin: 0;
            padding: 0;
            font-family: $header-font-family;
            font-size: rem(20px);
            &.small {
                font-size: rem(16px); // Small font
            }
            &.icon-alone {
                padding-left: 2rem;
            }
        }

        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6,
        .heading {
            padding: 0;
        } 
    }
    &__content {
        @include clearfix;
        padding: $space-m;
        overflow-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
        &.no-padding {
            padding: 0 !important;
        }
        p,
        li,
        cite {
            @include adjust-leading-to(0.85);
            font-size: rem(15px);
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
            &:first-child {
                margin-top: 0;
            }
        }
        
        h1,
        .h1{
            font-size: rem(30px);
        }

        h2,
        .h2{
            font-size: rem(22px);
        }

        h3,
        .h3{
            font-size: rem(20px);
        }

        h4,
        .h4{
            font-size: rem(18px);
        }

        h5,
        .h5{
            font-size: rem(17px);
        }

        h6,
        .h6{
            font-size: rem(16px);
        }       
        
        p:last-child {
            margin-bottom: 0;
        }
        li:first-child {
            margin-top: 0;
        }
        li:last-child {
            margin-bottom: 0;
        }
    }
    &__footer {
        padding: $space-m;
        @include border-shaded(top);
    }
}