@import "variables/border";
@import "variables/colour";
@import "variables/font";
@import "variables/input";
@import "variables/layout";
@import "mixins/layout";

// ------------------------------------------------------------------
//   Default tab
// ------------------------------------------------------------------

.tab{
    margin-bottom: $space-m;
    
    &__nav {
        display: none;

        div {
            display: inline-block;
            margin: 0;
            width: auto;
            font-family: $header-font-family;

            a{
                @include action-input;
                padding: $space-s;
                display: inline-block;
                border-bottom-color: $input-border-colour !important;
                margin-bottom: -1px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            &.is-active a {
                &,
                &:visited,
                &:hover,
                &:focus{
                    color: $action-input-colour;
                    background: $white;
                    border-color: $input-border-colour;
                    border-bottom-color: $white !important;
                    box-shadow: none;
                }
            }
        }
        
        @include mq(tablet-small) {
            display: block;
        }
    }

    &__item {
        &__header {
            @include mq(tablet-small) {
                display: none;
            }
        }
        &__content {
            display: none ;
            border: 1px solid $input-border-colour;
            padding: $space-m;
            background: $white;
            &.is-active {
                display: block;
            }
        }
    }
}

.no-js {
    .tab-nav {
        display: none ;
    }
    .tab__item__content {
        display: block ;
        border: none;
        padding: 0;
    }
}