@import "variables/font";
@import "functions/units";
@import "mixins/icons";

// ------------------------------------------------------------------
//   Curtin icons
// ------------------------------------------------------------------
.curtin-icon,
[class*="curtin-icon-"] {
    @include icon-style;
}

@include generate-icon-classes;

// ------------------------------------------------------------------
//   Social Media icons
// ------------------------------------------------------------------
.ss-icon {
    line-height: 1;
    vertical-align: middle; // Vertical aligned
    &.social-facebook {
        color: $social-facebook;
    }
    &.social-twitter {
        color: $social-twitter;
    }
    &.social-googleplus {
        color: $social-googleplus;
    }
    &.social-youtube {
        color: $social-youtube;
    }
    &.social-instagram {
        color: $social-instagram;
    }
    &.social-rss {
        color: $social-rss;
    }
    &.social-email {
        color: $social-email;
    }
    &.social-pinterest {
        color: $social-pinterest;
    }
    &.social-linkedin {
        color: $social-linkedin;
    }
    &.social-weibo {
        color: $social-weibo;
    }
    &.social-tumblr {
        color: $social-tumblr;
    }
}

// Video play icon
.icon-play {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    .icon-bg {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80px;
        height: 80px;
        margin-top: -40px;
        margin-left: -40px;
        border: 3px solid $white;
        background-color: $black; // Fallback
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 40px;
    }
    i {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -18px 0 0 -22px;
        color: $white;
        font-size: rem(36px);
    }
    // Hover & focus status
    &:hover,
    &:focus {
        .icon-bg {
            border-color: $yellow;
        }
        i {
            color: $yellow;
        }
    }
    // Active status
    &:active {
        .icon-bg {
            border-color: $secondary-colour;
            background-color: $white;
        }
        i {
            color: $secondary-colour;
        }
    }
}

// On carousel box
.carousel-box__item__media .icon-play {
    .icon-bg {
        width: 40px;
        height: 40px;
        margin-top: -20px;
        margin-left: -20px;
        border-width: 2px;
        border-radius: 20px;
    }
    i {
        margin: -8px 0 0 -7px;
        font-size: rem($base-font-size);
    }
}

// Social media icons next to the headings
.icon-alone {
    background-color: transparent;
    background-repeat: no-repeat;
    padding-left: 2rem;
}

.icon-twitter {
    background-image: url("../img/icon-twitter.png");
    background-position: 0 0.3em;
}

.icon-conversation {
    background-image: url("../img/icon-conversation.png");
    background-position: 0 0.4em;
}

.icon-instagram {
    background-image: url("../img/icon-instagram.png");
    background-position: 0 0.25em;
}

.icon-rss {
    background-image: url("../img/icon-rss.png");
    background-position: 0 0.3em;
}

.icon-facebook {
    background-image: url("../img/icon-facebook.png");
    background-position: 0 0.2em;
}

.icon-flickr {
    background-image: url("../img/icon-flickr.png");
    background-position: 0 0.45em;
}

.icon-download {
    background: {
        image: url("../img/icon-download-dark.svg");
    }
}

.icon-calendar {
    background: {
        image: url("../img/icon-calendar-dark.svg");
    }
}

.icon-external-link {
    background: {
        image: url("../img/icon-external.svg");
    }
}

.icon-alone {
    background-color: transparent;
    background-repeat: no-repeat;
    padding-left: 2rem !important;
}