@import "variables/border";
@import "variables/font";
@import "functions/layout";
@import "functions/units";
@import "mixins/images";
@import "mixins/lists";
@import "mixins/links";
@import "mixins/layout";
@import "mixins/text";

// Grid list
.grid-list {
    @include clearfix;
    @include no-bullets;
    &__item {
        width: 100%;
        margin-bottom: 1.5em;
        @include mq(tablet-small) {
            @include span-columns(6, 12, 4.5%, 4%); // for .grid-list__item sitting inside a standard content page - 2 items allowed
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__item__thumbnail {
        margin-bottom: $space-s;
        img {
            display: block;
            width: 100%;
            border: 1px solid $box-border-colour;
        }
    }
    &__item__title {
        font-family: $base-font-family;
        font-weight: $base-font-weight;
        text-transform: none;
        font-size: rem(17px);
        margin-bottom: $space-s;
        @include adjust-leading-to(1);
        a {
            @include link-colour($border: false);
        }
    }
}

@include mq(tablet-small){
    .full .grid-list .grid-list__item {
        // for .grid-list__item sitting inside a full width content page - 3 items allowed
        @include span-columns(4, 12);
    }
    
    .main-content__article .grid-list .grid-list__item{
        // for .grid-list__item sitting inside a news article page - 3 items to sit within an 8-col column
        @include span-columns(1, 3, 31.31%, 3.03%);
    }
}