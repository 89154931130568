@import "variables/layout";
@import "mixins/layout";
@import "mixins/lists";

.block-grid{
    @include clearfix;
}

@for $i from 1 through $max-block-grid-items {
    .main-content .block-grid-#{$i},
    .block-grid-#{$i}{
        @extend .block-grid;

        .block-grid-item,
        > li{
            @include block-grid-item($i);
        }
    }

    ul.block-grid-#{$i}{
        @include no-bullets;
    }

    @for $col from 1 through $block-grid-density-increase-threshold {
        .col-#{$col}{
            .block-grid-#{$i}{
                .block-grid-item,
                > li{
                    @include mq($max: tablet-small){
                        &:nth-child(#{ceil($i/2)}n){
                            margin-right: 4%;
                        }                        
                    }
                    
                    @include mq($max: tablet-land){
                        &:nth-child(#{$i}n){
                            margin-right: 4%;
                        } 

                        @include block-grid-item($i * 2);
                    }                        
                }
            }
        }
    }
}