@import "variables/layout";
@import "mixins/layout";

// ------------------------------------------------------------------
//   Main content section in standard pages
// ------------------------------------------------------------------
.main-content {
    @include columns(6);
    margin-top: $space-l;

    // For standard pages with no right hand column
    &.full {
        @include columns(9); 
        @include last;
    }

    &.no-nav{
        @include columns(9);        
    }

    &.full.no-nav{
        @include columns(12); 
    }
}