@import "variables/colour";
@import "variables/layout";
@import "mixins/layout";

// ------------------------------------------------------------------
//   Promo list
//   A group of mini promo items
// ------------------------------------------------------------------
.promotion-tertiary {
    & .list__item {
        @include clearfix;
        margin: 0;
        border-top: 1px solid $white;
        border-bottom: 1px solid $grey40;
        list-style: none;
        padding: $space-m;
        &:last-child {
            border-bottom: none;
        }
    }
}

.secondary-content .promotion-tertiary .list__item {
    @include mq(tablet-land) {
        padding: $space-s;
    }
}
