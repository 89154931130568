@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/colour";
@import "mixins/layout";
@import "mixins/lists";
@import "components/block-grid";

// ------------------------------------------------------------------
//   Variables
// ------------------------------------------------------------------
$special-tiles: (
    blackboard: #333333,
    estudent: #006394,
    oasis-account: #006699,
    ask-curtin: #10adbc

) !default;

$lowercase-prefix: 
    estudent 
    iportfolio
    epay
    lowercase-prefix
    !default;

.tile{
    @extend .block-grid-item;
    position: relative;
    overflow: hidden;

    .tile__content{
        text-align: center;
        vertical-align: middle;
        background: $list-link-background-colour-hover--high-contrast;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        > *{
            position: relative;
        }

        &:hover{
            background: $list-link-background-colour-active--high-contrast;

            a{
                color: $list-link-colour-active--high-contrast;
            }
        }
    }

    .tile__media{
        width: 100%;
        height: 100%;
        position: absolute;

        img{
            width: auto;
            height: auto;
            position: absolute;
            margin: auto;
        }
    }

    .tile__title {
        width: 100%;
        height: 100%;
        display: table;

        a{
            display: table-cell;
            vertical-align: middle;
            border-bottom: none;
            text-decoration: none;
            text-transform: uppercase;
            font-family: $header-font-family;
            font-weight: $bold;
            font-size: 1rem;
            color: $list-link-colour-hover--high-contrast;
            line-height: 1.25rem;
            padding: $space-xs;
        }
    }

    &::before{
        content: " ";
        display: block;
        padding-bottom: 63%;
    }

    // ------------------------------------------------------------------
    //   Icon and Image tiles
    // ------------------------------------------------------------------
    &--icon,
    &--image{
        @extend .tile;
    }

    &--image{
        .tile__media{
            > img{
                max-height: 100%;
                max-width: 100%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }  
    
    &--icon{
        .tile__content{
            &:hover{
                .tile__media {
                    img{
                        opacity: 1;
                    }
                    & > span{
                        color: watermark($secondary-colour);
                    }
                }
            }
        }
        .tile__media{
            display: table;

            > img{
                $icon-image-size: 65%;

                max-height: $icon-image-size;
                max-width: $icon-image-size;
                top: (100%-$icon-image-size)/2;
                bottom: (100%-$icon-image-size)/2;
                left: (100%-$icon-image-size)/2;
                right: (100%-$icon-image-size)/2;

                opacity: 0.7;
            }

            > span{
                display: table-cell;
                vertical-align: middle;
                font-size: 3rem;
                color: watermark($primary-colour);
            }
        }

        @each $tile-name, $tile-colour in $special-tiles{
            &--#{$tile-name}{
                .tile__content{
                    background: $tile-colour;

                    &:hover{
                        background: watermark($tile-colour);

                        a{
                            color: text-contrast(watermark($tile-colour));
                        }
                        
                        .tile__media {
                            & > span{
                                color: watermark(watermark($tile-colour));
                            }
                        }
                    }
                }
                .tile__media{
                    > span{
                        color: watermark($tile-colour);
                    }        
                }
                .tile__title {
                    a{
                        color: text-contrast($tile-colour);
                    }
                }
            }
        }

        @each $tile-name in $lowercase-prefix{
            &--#{$tile-name}{
                .tile__title a:first-letter{
                    text-transform: lowercase;
                }
            }
        }        
    }   
}

@include mq($max: tablet-land){
    @for $col from 1 through $block-grid-density-increase-threshold {
        .col-#{$col}{
            .block-grid-1{
                .tile--icon,
                .tile--image{
                    &:nth-child(1n),
                    &:nth-child(2n){
                        margin-right: 4%;
                    }
                    
                    @include block-grid-item(3, $small-per-row: 3);                    
                }
            }
        }
    }
}
