@import "functions/text";

// ------------------------------------------------------------------
//   Letterpress - white text shadow
//
//   Syntax:
//   .title {
//     @include letterpress-white(0.4);
//   }
// ------------------------------------------------------------------

@mixin letterpress-white($opacity: 1) {
  text-shadow: white($opacity) 0 1px 0;
}


// ------------------------------------------------------------------
//   Letterpress - black text shadow
//
//   Syntax:
//   .title {
//     @include letterpress-black(0.4);
//   }
// ------------------------------------------------------------------

@mixin letterpress-black($opacity: 1) {
  text-shadow: black($opacity) 0 -1px 0;
}

// ------------------------------------------------------------------
//   Letterpress - text shadow
//
//   Syntax:
//   .title {
//     @include letterpress($black, 0.4);
//   }
// ------------------------------------------------------------------
@mixin letterpress($colour: $text-colour, $opacity: 1) {
  text-shadow: transparentize(grayscale(invert($colour)), 1-$opacity) 0 -1px 0;
}

@mixin adjust-leading-to($lines, $font-size: $base-font-size) {
  line-height: $font-unit * $lines * $base-line-height / $font-size;
}

@mixin adjust-font-size-to($to-size, $lines: lines-for-font-size($to-size), $from-size: $base-font-size) {
  font-size: $font-unit * $to-size / $from-size;
  @include adjust-leading-to($lines, $to-size);
}