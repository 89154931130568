#lang_sel {height:32px; position:relative; font-family: verdana, arial, sans-serif; display: inline-block;}
/* hack to correct IE5.5 faulty box model */
* html #lang_sel {width:12em; w\idth:12em;}
/* remove all the bullets, borders and padding from the default list styling */
#lang_sel ul, #lang_sel li {padding:0 !important; margin:0 !important; list-style-type:none !important;}
#lang_sel li:before{content:'' !important;}
#lang_sel ul ul {width:149px;}
/* float the list to make it horizontal and a relative positon so that you can control the dropdown menu positon */
#lang_sel li {float:left;width:149px;position:relative;}
/* style the links for the top level */
#lang_sel a, #lang_sel a:visited {display:block;font-size:11px;text-decoration:none !important; color:#444444; border:1px solid #cdcdcd; background:#fff; padding-left:10px; line-height:24px;}
/* a hack so that IE5.5 faulty box model is corrected */
* html #lang_sel a, * html #lang_sel a:visited {width:149px; w\idth:138px;}

/* hide the sub levels and give them a positon absolute so that they take up no room */
#lang_sel ul ul {visibility:hidden;position:absolute;height:0;top:25px;left:0; width:149px;border-top:1px solid #cdcdcd;}
/* another hack for IE5.5 */
* html #lang_sel ul ul {top:18px;t\op:19px;}

/* style the table so that it takes no ppart in the layout - required for IE to work */
#lang_sel table {position:absolute; top:0; left:0; border-collapse:collapse;}

/* style the second level links */
#lang_sel ul ul a, #lang_sel ul ul a:visited {background:#ffffff; color:#444444; height:auto; line-height:1em; padding:5px 10px;border-width:0 1px 1px 1px;}
/* yet another hack for IE5.5 */
* html #lang_sel ul ul a, * html #lang_sel ul ul a:visited {width:150px;w\idth:128px;}

/* style the top level hover */
#lang_sel a:hover, #lang_sel ul ul a:hover{color:#000; background:#eee;}
#lang_sel :hover > a, #lang_sel ul ul :hover > a {color:#000; background:#eee;}

#lang_sel a.lang_sel_sel{
    background:url(img/nav-arrow-down.png) #fff right no-repeat;
    color:#444;
}
#lang_sel a.lang_sel_sel:hover{
    text-decoration: none;
    color:#000;
}

/* make the second level visible when hover on first level list OR link */
#lang_sel ul li:hover ul,
#lang_sel ul a:hover ul{visibility:visible; }

#lang_sel img.iclflag{width:18px;height:12px;position: relative; top: 1px;}





#lang_sel_footer {
  margin: 0;
  padding: 7px;
  text-align: center;
  font: 11px Verdana, sans-serif;
  min-height: 15px;
  clear: both;
  background-color: #fff;
  border: 1px solid #cdcdcd;
}

#lang_sel_footer ul {
  list-style: none;
  margin:0;
  padding:0;
}

#lang_sel_footer ul li img {
  position: relative;
  top: 1px;
  width: 18px;
  height: 12px;
}

#lang_sel_footer ul li {
  display:inline;
  margin:0 1px 0 0;
  padding:0;
  white-space: nowrap;
  line-height: 25px;
}

#lang_sel_footer ul li a, #lang_sel_footer ul li a:visited {
  text-decoration: none;
  padding: 5px 10px;
}

#lang_sel_footer ul li a:hover, #lang_sel_footer ul li a:active {}

#wpml_credit_footer { width: 100%; margin: 10px 0; padding: 0; text-align: center; font-size: 11px; }





#lang_sel_list {height:32px; position:relative; z-index:99;  font-family: verdana, arial, sans-serif;}
#lang_sel_list.lang_sel_list_vertical{width:149px;}

/* hack to correct IE5.5 faulty box model */
* html #lang_sel_list {width:12em; w\idth:12em;}
/* remove all the bullets, borders and padding from the default list styling */
#lang_sel_list ul, #lang_sel_list li {padding:0 !important; margin:0 !important; list-style-type:none !important;}
#lang_sel_list li:before{content:'' !important;}
#lang_sel_list ul.lang_sel_list_vertical {width:149px;}
/* float the list to make it horizontal and a relative positon so that you can control the dropdown menu positon */
#lang_sel_list li {float:left;position:relative;}
#lang_sel_list.lang_sel_list_vertical li {width:149px;}

/* style the links for the top level */
#lang_sel_list a, #lang_sel_list a:visited {display:block;font-size:11px;text-decoration:none !important; color:#444444; background:#fff;  line-height:18px;padding-left:5px;}
#lang_sel_list.lang_sel_list_vertical a, #lang_sel_list.lang_sel_list_vertical a:visited{border: 1px solid #cdcdcd; border-top-width: 0; padding-left:10px;}


/* a hack so that IE5.5 faulty box model is corrected */
* html #lang_sel_list a, * html #lang_sel_list a:visited {width:149px; w\idth:138px;}

/* hide the sub levels and give them a positon absolute so that they take up no room */
#lang_sel_list.lang_sel_list_vertical ul {/*visibility:hidden;position:absolute;*/height:0;top:19px;left:0; border-top:1px solid #cdcdcd;}


/* another hack for IE5.5 */
* html #lang_sel_list ul {top:18px;t\op:19px;}

/* style the table so that it takes no ppart in the layout - required for IE to work */
#lang_sel_list table {position:absolute; top:0; left:0; border-collapse:collapse;}

/* style the second level links */
#lang_sel_list ul a, #lang_sel_list_list ul a:visited {background:#ffffff; color:#444444; height:auto; line-height:1em;}
#lang_sel_list.lang_sel_list_vertical ul a, #lang_sel_list_list ul a:visited {padding:3px 10px;}

/* yet another hack for IE5.5 */
* html #lang_sel_list ul a, * html #lang_sel_list ul a:visited {width:150px;w\idth:128px;}

#lang_sel_list a.lang_sel_sel{
    background-image: none;
    color:#444;
}
#lang_sel_list a.lang_sel_sel:hover{
    text-decoration: none;
    color:#000;
}

/* make the second level visible when hover on first level list OR link */
#lang_sel_list ul li:hover ul,
#lang_sel_list ul a:hover ul{visibility:visible; }

#lang_sel_list img.iclflag{width:18px;height:12px;position: relative; top: 1px;}

#lang_sel.icl_rtl{
    text-align:right;
    direction: rtl;
}
#lang_sel.icl_rtl .lang_sel_sel{
    padding-right:14px;
}

/* reset menu img definitions */
.menu-item-language img.iclflag{height:12px !important; width:18px !important; margin-bottom:0 !important; margin-right:4px;}
