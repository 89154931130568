@import "variables/border";
@import "variables/colour";
@import "variables/layout";
@import "functions/colour";
@import "functions/layout";
@import "mixins/layout";
@import "mixins/links";
@import "mixins/lists";
@import "mixins/icons";
@import "mixins/text";

// ------------------------------------------------------------------
//   Link list
// ------------------------------------------------------------------
.link-list {
    @include clearfix;
    border-top: 1px solid $grey40;
    border-bottom: 1px solid $grey40;
    padding-bottom: 0;
    @include no-bullets; // removes default bullet style
    &.no-top-border {
        // removes top border of linked list for use in boxes, etc
        border-top: 0;
        li {
            &:first-child {
                a {
                    border-top: none;
                }
            }
        }
    }
    li {
        list-style: none;
        margin: 0 !important;
        font-size: rem(15px);
        @include adjust-leading-to(1);
        a {
            display: block;
            position: relative;
            border-top: 1px solid $white;
            border-bottom: 1px solid $grey40;
            text-decoration: none;
            @include list-link-colour;
            padding-top: rem(7px);
            padding-bottom: rem(7px);
            padding-left: $space-m; 
            padding-right: $space-x;
            // Right arrow
            &:before {
                @include arrow($position: absolute);
            }
            // Meta info
            span {
                display: inline-block;
                color: $text-colour;
                min-width: rem(60px);
            }
        }
        &.is-active a{
            background-color: $list-link-background-colour-hover;
            color: $list-link-colour-hover;
        }        
        &:last-child a {
            // last list item
            border-bottom: none;
        }
        time {
            display: block;
            color: $black80;
            font-weight: $bold;
        }
        a {
            &:focus,
            &:active {
                time {
                    color: $white;
                }
            }
        }
    }

    // ------------------------------------------------------------------
    //   Link list - high contrast
    // ------------------------------------------------------------------
    &{
        &--high-contrast {
            @extend .link-list;
            border-top: 1px solid $black;
            li {
                a {
                    border-top: 1px solid shade($grey, 55);
                    border-bottom: 1px solid $black;
                    @include list-link-colour--high-contrast;
                }
                &.is-active a{
                    background-color: $list-link-background-colour-hover--high-contrast;
                    color: $list-link-colour-hover--high-contrast;
                }            
            }
        }

        &--horizontal{
            @include clearfix;
            @include no-bullets; // removes default bullet style
            display: inline-block;

            &.no-top-border {
                // removes top border of linked list for use in boxes, etc
                li {
                    a {
                        border-top: none;
                    }
                }
            }
            li {
                list-style: none;
                margin: 0 !important;
                font-size: rem(15px);
                float: left;
                @include adjust-leading-to(1);            
                a {
                    display: block;
                    position: relative;
                    border: 1px solid $grey40;
                    border-left: none;
                    text-decoration: none;
                    @include list-link-colour;
                    padding: 0.375rem 2rem;

                    // Meta info
                    span {
                        display: inline-block;
                        color: $text-colour;
                        min-width: rem(60px);
                    }
                }
                &.is-active a{
                    background-color: $list-link-background-colour-hover;
                    color: $list-link-colour-hover;
                }            
                &:first-child a {
                    // first list item
                    border-left: 1px solid $grey40;
                }            
                time {
                    display: block;
                    color: $black80;
                    font-weight: $bold;
                }
                a {
                    &:focus,
                    &:active {
                        time {
                            color: $white;
                        }
                    }
                }
            }
        }
    }    
}

.box .link-list,
.box--secondary .link-list,
.box--tertiary .link-list,
.box--high-contrast .link-list,
.accordion {
    border-bottom: none;
}
    