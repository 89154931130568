@import "variables/colour";
@import "variables/border";
@import "variables/font";
@import "variables/layout";
@import "functions/colour";
@import "functions/layout";
@import "functions/units";
@import "mixins/layout";
@import "mixins/icons";
@import "mixins/links";
@import "mixins/lists";
@import "mixins/input";
@import "mixins/text";
@import "elements/icon";

.page-footer{
    @include clearfix;
    background: $white;
    border-bottom: 3px solid $gold;
    border-top: 3px solid $yellow;

    margin-top: $space-l;
    @include mq(tablet-land) {
        margin-top: $space-xx;
    }

    p:last-child{
        margin-bottom: 0;
    }

    &__section {
        @include clearfix;
        padding: $space-l 0;

        &.highlight{
            background: $body-colour;
        }
        .module:last-child {
            margin-bottom: 0;
        }

        .address {
            @include mq(tablet-land) {
                width: 30%;
                float: left;                
            }

            img {
                display: block;
                width: 100%;
                margin-bottom: $space-s;
                border-radius: $border-radius;
            }            
        }        
    }

    &__nav {
        margin-top: $space-l;

        &:first-child{
            margin-top: 0;
        }

        @include mq(tablet-small){
            width: 48%;
            float: left;
            margin-left: 1%;
            margin-right: 1%;

            &:nth-child(2) {
                margin-top: 0;
            }

            &:nth-child(odd){
                clear: left;
            }
        }
        @include mq(tablet-land) {
            width: 23%;
            margin-top: 0;

            &:nth-child(odd){
                clear: none;
            }            
        }

        a{
            @include adjust-leading-to(1, 14px);
            display: block;

            @include link-colour( $normal: $link-colour, $hover: $link-colour, $visited: $link-colour, $border: false );
            font-size: rem(14px);
            text-decoration: underline;

            @include mq(wide-screen) {
                @include adjust-leading-to(1.3);
                font-size: rem(15px);
            }
        }
    }

    &__label {
        font-size: rem(18px);
        @include mq(tablet-small) {
            display: inline-block;
        }
        @include mq(wide-screen) {
            font-size: rem(19px);
        }
    }

    .contact-list {
        @include no-bullets;
        font-size: rem(14px);

        li {
            margin-top: $space-xs;

            &:first-child {
                margin-top: 0;
            }
        }

        span {
            display: block;
            font-weight: $bold;
        }

        @include mq(tablet-small) {
            span {
                display: inline-block;

                width: rem(120px);
                margin-left: rem(-130px);
                margin-right: rem(10px);
            }

            li {
                padding-left: rem(130px);
            }
        }
    }

    .copyright {
        @include columns(6);
        @include last;

        font-size: rem(14px);
        
        @include mq(tablet-land) {
            text-align: right;
        }

        .inline-list{
            margin-bottom: $space-s;
        }
    }

    .social-media {
        @include mq(tablet-port) {
            float: left;
            width: 60%;
            margin-bottom: 0;
            line-height: rem(50px);
        }

        &__nav{
            text-align: center;

            @include mq(tablet-small) {
                display: inline-block;
                margin-left: $space-m;
            }

            a {
                @include link-colour($black70, $black70, $black70, $black70, $black70, false);
                margin-left: $space-xs;

                &:first-child {
                    margin-left: 0;
                }
            }

            // Icons
            .ss-icon {
                position: relative;
                top: 1px;
                color: $black75;
                line-height: normal;
                font-size: rem(30px);
            }
        }

        .page-footer__label {
            text-align: center;
            margin-bottom: $space-l;

            @include mq(tablet-port) {
                margin: 0;
            }
        }
    }

    .cricos {
        @include columns(6);        
        font-size: rem(14px);
    }

    .logos{
        @include mq(tablet-port) {
            width: 40%;
            float: right;
            margin-bottom: 0;
            text-align: right;
        }

        a{
            border: none;
        }

        .logo-item {
            display: block;
            float: left;
            width: 40%;
            vertical-align: middle;
            margin: $space-xs 5%;

            @include mq(tablet-port) {
                width: auto;
                float: none;
                display: inline-block;
                margin: 0 0 0 $space-m;
            }

            @include mq(tablet-land) {
                margin-left: $space-x;
            }
        }

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }

        .atn {
            width: 150px;
        }

        .edx {
            width: 80px;
        }                             
    }

    .indigenous-welcome {
        p {
            font-size: rem(14px);

            @include mq(tablet-small) {
                max-width: rem(500px);
            }
        }

        .wrapper {
            @include mq(tablet-small) {
                background: url('../img/bg-indigenous-welcome.png') no-repeat 0 0;
                min-height: rem(79px);
                padding-left: rem(100px);
                padding-top: $space-xs;
            }
        }
    }
}
