@import "variables/icon";

// ------------------------------------------------------------------
//   Includes an icon from the default icon set, allows overriding the
//   set if required
//
//   Syntax:
//   i {
//     @include icon(close);
//   }
// ------------------------------------------------------------------
@mixin icon($name, $includeStyles: true, $icon-set: $icon-set, $position: before){
    $selector: if($position, "&:#{$position}", "&");
	#{$selector} {
		@if $includeStyles {
            @include icon-style($icon-font: map-get($icon-set, "set-font"));
        }
        content: map-get($icon-set, $name);
	}
}

// ------------------------------------------------------------------
//   Icon styling, allows overriding the default icon font with a 
//   target one if required
//
//   Syntax:
//   i {
//     @include icon-style;
//     color: #fff;
//   }
// ------------------------------------------------------------------
@mixin icon-style($icon-font: $icon-font) {
	font-family: "#{$icon-font}";
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// ------------------------------------------------------------------
//   Generates classes for each icon in a set
//
//   Syntax:
//   @include icon-style;
// ------------------------------------------------------------------
@mixin generate-icon-classes($icon-set: $icon-set, $class-prefix: 'curtin-icon') {
    @each $icon-name, $icon-value in $icon-set {
        @if ($icon-name != "set-font") {
            .#{$class-prefix}-#{$icon-name}:before{
                content: $icon-value;
            }
        }
    }    
}

// ------------------------------------------------------------------
//   Arrows, mainly used in links
//
//   Syntax:
//   a:before {
//     color: #fff;
//     @include arrow( $position: absolute, $direction: down );
//   }
// ------------------------------------------------------------------
@mixin arrow($right-padding: 1.5em, $wide: false, $position: static, $direction: right) {
    // Position
    @if $position==static {
        margin-left: 1em;
    }
    @else {
        position: absolute;
        top: 50%;
        right: $right-padding;
    }
    
    $icon-prefix: if($wide, "arrow-wide-", "arrow-");
    @include icon(#{$icon-prefix}#{$direction}, $position: null);

    @if $wide==true {
        // Wide arrow
        margin-top: -8px;
        font-size: rem(16px);
    }
    @else {
        // Standard arrow
        margin-top: -4px;
        font-size: rem(10px);
    }
}

// ------------------------------------------------------------------
//   CSS triangle
//
//   Syntax:
//   a:after {
//     content: '';
//     top: 20px;
//     left: 40px;
//     position: absolute;
//     @include triangle(16px, 8px, down, $ccc);
//   }
// ------------------------------------------------------------------

@mixin triangle($width: 20px, $height: 20px, $direction: up, $color: red) {
  width: 0;
  height: 0;

  // Right
  @if $direction == right {
    border-top: $height/2 solid transparent;
    border-bottom: $height/2 solid transparent;
    border-left: $width solid $color;
  }

  // Left
  @if $direction == left {
    border-top: $height/2 solid transparent;
    border-bottom: $height/2 solid transparent;
    border-right: $width solid $color;
  }

  // Up
  @if $direction == up {
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-bottom: $height solid $color;
  }

  // Down
  @if $direction == down {
    border-left: $width/2 solid transparent;
    border-right: $width/2 solid transparent;
    border-top: $height solid $color;
  }
}