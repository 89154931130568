@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/layout";
@import "functions/units";
@import "mixins/layout";
@import "mixins/icons";
@import "mixins/images";
@import "mixins/links";
@import "mixins/text";

// Carousel box - for videos, books etc
.carousel-box {
    @include clearfix;
    position: relative;
    background-color: $black80;
    padding: 1rem;
    @include mq(tablet-port) {
        padding-left: 2.5em;
        padding-right: 2.5em;
    }

    // ------------------------------------------------------------------
    //   Carousel Box components
    // ------------------------------------------------------------------
    &{
        // Title
        &__title {
            position: relative;
            color: $white;
            font-family: $header-font-family;
            font-weight: $header-font-weight;
            text-transform: uppercase;
            font-size: rem(22px);
            a {
                display: block;
                @include link-colour--high-contrast($border: false);
                // Right arrow
                &:after {
                    @include arrow($wide: true);
                    margin-top: -2px;
                }
            }
        }
        // Intro Text
        &__intro {
            @include clearfix;
            margin-top: rhythm(0.5, 20px);
        }
        &__content {
            margin-top: rhythm(1/2);
            padding-top: rhythm(1/2);
            padding-bottom: rhythm(1/2);
            @include mq(tablet-port) {
                border: 1px solid $black;
                box-shadow: inset 0 2px 5px rgba(0, 0, 0, .57);
            }
        }
        &__item {
            margin-left: 1em;
            margin-right: 1em;
            color: $white;
            &__media {
                margin-bottom: rhythm(1/2);
                img {
                    display: block;
                    width: 100%;
                    border: 1px solid $grey90;
                }
                a {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    @include image-clickable;
                }
            }
            &__title {
                margin-bottom: rhythm(1/2);
                font-size: rem(16px);
                a {
                    @include link-colour--high-contrast($border: false);
                }
            }
            &__content {
                @include adjust-font-size-to(15px);
            }
        }
        // Column layout - e.g. used in R&D Now
        &__item--column {
            @include clearfix;
            &__item__media {
                float: left;
                width: $thumbnail-medium;
            }
            &__item__content {
                margin-left: $thumbnail-medium+ $base-font-size;
            }
        }
        // Footer
        &__footer {
            margin-top: rhythm(0.5);
            .button {
                margin-bottom: 0; // Remove button's margin bottom
            }
        }
        &__prev,
        &__next {
            display: block;
            position: relative;
            height: 3em;
            width: 48%;
            background-color: $yellow;
            float: left;
            @include mq(tablet-port) {
                position: absolute;
                top: 50%;
                height: 3em;
                width: 2.5em;
                margin-top: -1.5em;
            }
            &:after {
                color: $black85;
            }
            &:before {
                color: $white;
            }
            &:hover {
                cursor: pointer;
                background-color: $white;
            }
        }
        &__prev {
            margin-right: 4%;
            @include mq(tablet-port) {
                left: 0;
                border-right: none;
            }
            // Arrow
            &:before {
                left: 50%;
                margin-left: -5px;
                margin-top: -7px;
                @include arrow( $wide: true, $direction: left, $position: absolute);
            }
            // Arrow shadow
            &:after {
                left: 50%;
                margin-left: -5px;
                @include arrow( $wide: true, $direction: left, $position: absolute);
            }
        }
        &__next {
            @include mq(tablet-port) {
                right: 0;
                border-left: none;
            }
            // Arrow
            &:before {
                left: 50%;
                margin-left: -3px;
                margin-top: -7px;
                @include arrow( $wide: true, $position: absolute);
            }
            // Arrow shadow
            &:after {
                left: 50%;
                margin-left: -3px;
                @include arrow( $wide: true, $position: absolute);
            }
        }
    }
}

// Carousel box - reversed
.carousel-box--reversed {
    border: 1px solid $grey60;
    background-color: $grey15;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .25);
    // Title
    .carousel-box__title {
        color: shade($grey, 65);
        a {
            color: shade($grey, 65);
        }
    }
    // Content
    .carousel-box__content {
        background-color: $white;
        padding-left: rhythm(1/2);
        padding-right: rhythm(1/2);
        @include mq(tablet-port) {
            border: 1px solid $grey70;
            padding-left: 0;
            padding-right: 0;
            box-shadow: inset 0 1px 6px rgba(0, 0, 0, .2);
        }
    }
    // Item
    .carousel-box__item {
        color: shade($grey, 45);
        // Item title
        .carousel-box__item__title {
            a {
                @include link-colour($border: false);
            }
        }
        // Media
        .carousel-box__item__media {
            img {
                border-color: $grey80;
            }
        }
    }
}