@import "functions/colour";
@import "variables/palette";

// ------------------------------------------------------------------
//   Base
// ------------------------------------------------------------------
$body-colour:              $grey5 !default;
$text-colour:              $black65 !default;
$text-colour-hover:        $grey-dark !default;

$primary-colour:           $yellow !default;
$secondary-colour:         $orange !default;

$primary-colour__text:     $black80 !default;
$secondary-colour__text:   $white !default;

$header-colour:            $black !default;
$header-background-colour: $primary-colour !default;


// ------------------------------------------------------------------
//   Standard content link colours
// ------------------------------------------------------------------
$link-colour:                         $blue !default;
$link-colour-hover:                   $purple !default;
$link-colour-active:                  $secondary-colour !default;
$link-colour-visited:                 tint($purple, 35) !default;
$link-colour-focus:                   $black !default;

$link-colour--high-contrast:          $white !default;
$link-colour-hover--high-contrast:    $primary-colour !default;
$link-colour-active--high-contrast:   tint($primary-colour,85) !default;
$link-colour-visited--high-contrast:  $grey45 !default;
$link-colour-focus--high-contrast:    $black !default;


// ------------------------------------------------------------------
//   List link and background colours
// ------------------------------------------------------------------
$list-link-colour:                                  $blue !default;
$list-link-colour-hover:                            $black80 !default;
$list-link-colour-active:                           $white !default;
$list-link-colour-visited:                          tint($purple, 35) !default;
$list-link-colour-focus:                            $white !default;

$list-link-background-colour:                       $grey10 !default;
$list-link-background-colour-hover:                 $primary-colour !default;
$list-link-background-colour-active:                $secondary-colour !default;
$list-link-background-colour-visited:               $grey10 !default;
$list-link-background-colour-focus:                 $secondary-colour !default;

$list-link-colour--high-contrast:                   $white !default;
$list-link-colour-hover--high-contrast:             $black80 !default;
$list-link-colour-active--high-contrast:            $white !default;
$list-link-colour-visited--high-contrast:           $white !default;
$list-link-colour-focus--high-contrast:             $black80 !default;

$list-link-background-colour--high-contrast:        $black80 !default;
$list-link-background-colour-hover--high-contrast:  $primary-colour !default;
$list-link-background-colour-active--high-contrast: $secondary-colour !default;
$list-link-background-colour-visited--high-contrast:$black80 !default;
$list-link-background-colour-focus--high-contrast:  $primary-colour !default;


// ------------------------------------------------------------------
//   Images
// ------------------------------------------------------------------
$image-border-colour:       $grey70 !default;
$image-hover-border-colour: $primary-colour !default;


// ------------------------------------------------------------------
//   Borders
// ------------------------------------------------------------------
$border-colour:         $grey40 !default;


// ------------------------------------------------------------------
//   Box drop shadow
// ------------------------------------------------------------------
$box-border-colour:     $grey60 !default;
$box-shadow-colour:     rgba(0,0,0,.1) !default;


// ------------------------------------------------------------------
//   Inner box drop shadow
// ------------------------------------------------------------------
$inner-box-shadow-colour:$black20 !default;
$inner-box-shadow-x:     0 !default;
$inner-box-shadow-y:     1px !default;
$inner-box-shadow-blur:  10px !default;
