@import "variables/colour";

// ------------------------------------------------------------------
//   Box drop shadow
// ------------------------------------------------------------------

$box-shadow-x:          0 !default;
$box-shadow-y:          2px !default;
$box-shadow-blur:       3px !default;


// ------------------------------------------------------------------
//   Inner box drop shadow
// ------------------------------------------------------------------

$inner-box-shadow-colour:$black20 !default;
$inner-box-shadow-x:     0 !default;
$inner-box-shadow-y:     1px !default;
$inner-box-shadow-blur:  10px !default;


// ------------------------------------------------------------------
//   Borders
// ------------------------------------------------------------------

$border-colour:         $grey40 !default;
$border-radius:         3px !default;