@import "variables/colour";
@import "variables/border";
@import "variables/layout";
@import "functions/units";
@import "mixins/input";
@import "mixins/text";

// ------------------------------------------------------------------
//   Accortion item
// ------------------------------------------------------------------
.accordion__item {
    margin-bottom: rem(8px);
    &:last-of-type {
        margin-bottom: 0;
    }

    // ------------------------------------------------------------------
    //   Accortion item header
    // ------------------------------------------------------------------
    &__header {
        position: relative;
        margin: 0; // Remove any margins
        padding: 0; // Remove any paddings
        a {
            @include action-input;
            width: 100%;
            margin-bottom: 0 !important; // Overwrite margin bottom
            font-family: $header-font-family;
            text-align: left;
            text-transform: none;
            padding: 0.75rem $space-xx 0.75rem $space-m;
            border-radius: $border-radius;
            font-size: rem(17px);
            // Meta description
            span {
                display: block;
                //margin-top: $space-m;
                font-family: $base-font-family;
                font-weight: $medium;
                text-transform: none;
                font-size: rem(14px);
                @include adjust-leading-to(1);
                margin-top: $space-s;
            }
            &.is-expanded {
                border-color: $primary-colour; // Overwrite the existing border colour
                border-bottom-color: $grey80;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                &:hover {
                    border-bottom-color: $primary-colour;
                }
            }
        }
        // Toggle icon
        i {
            position: absolute;
            color: $purple;
            top: $space-m;
            right: $space-m;
            font-size: rem(19px);
        }
    }

    // ------------------------------------------------------------------
    //   Accordion item content
    // ------------------------------------------------------------------
    &__content {
        display: none;
        border: 1px solid $primary-colour;
        border-top: none;
        background: $white;
        @include adjust-font-size-to(15px);
        box-shadow: inset $inner-box-shadow-colour $inner-box-shadow-x $inner-box-shadow-y $inner-box-shadow-blur;
        padding: $space-m;

        p,
        li {
            font-size: rem(15px);
            @include adjust-leading-to(1);
        }
        p {
            margin-bottom: $space-m;
            &:last-child {
                margin-bottom: 0;
            }
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-weight: $bold;
            color: $black80;
            font-size: rem(17px);
            margin: $space-m 0 $space-xs 0;
        }
        // Link list
        .link-list {
            margin-top: (-$space-m); // Remove paddings by adding negative margin
            margin-left: (-$space-m); // Remove padding left by adding negative margin
            margin-right: (-$space-m); // Remove padding left by adding negative margin
            margin-bottom: (-$space-m); // Remove padding left by adding negative margin
            &+p,
            &+ol,
            &+ul {
                margin-top: $space-x;
            }
        }
        p+.link-list,
        ol+.link-list,
        ul+.link-list {
            margin-top: $space-m;
        }
    }
}

// No JS fallback
.no-js .accordion__item__content {
    display: block;
}