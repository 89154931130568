@import "variables/colour";
@import "variables/layout";
@import "functions/layout";

// ------------------------------------------------------------------
//  Select
// ------------------------------------------------------------------
select {
    display: block;
    color: $black60;
    height: rem(40px);
    margin: 0 0 $space-xs 0;
    padding: rem(6px 0 4px 0);
    max-width: 100%;
    &:focus {
        padding: rem(6px 0 4px 0);
    }
}