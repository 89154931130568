@import "variables/animation";
@import "variables/button";

// ------------------------------------------------------------------
//   Default template for the base of drop down lists, acordians and buttons
// ------------------------------------------------------------------
%action-input{
    transition: all $transition-speed $transition-style;
    display: inline-block;
    line-height: normal;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    width: 100%; // Full width for mobile
    cursor: pointer;
    font-family: $button-font-family;
    outline: none;
    -webkit-appearance: none; // Remove platform specific styles
    background-clip: padding-box; // Prevents bg colour from leaking outside the border
    border-radius: $button-radius; // Border radius
    font-size: $button-font-size;
    user-select: none; // Text unselectable
    &:disabled {
        // Disabled status
        opacity: 0.5;
        cursor: not-allowed;
    }
    &.full-width {
        width: 100%;
    }
}
