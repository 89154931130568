@at-root {
    // ------------------------------------------------------------------
    //   Web font - Sansa Soft Pro
    // ------------------------------------------------------------------
    @font-face {
        font-family: 'SansaSoft';
        src: url('../fonts/sansasoft-normal-webfont.eot');
        src: url('../fonts/sansasoft-normal-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/sansasoft-normal-webfont.woff') format('woff'), url('../fonts/sansasoft-normal-webfont.ttf') format('truetype'), url('../fonts/sansasoft-normal-webfont.svg#sansa_soft_stdnormal') format('svg');
        font-weight: normal;
        font-style: normal;
    }
    // ------------------------------------------------------------------
    //   Web font - Gandhi Sans Regular
    // ------------------------------------------------------------------
    @font-face {
        font-family: 'GandhiSansRegular';
        src: url('../fonts/GandhiSans-Regular-webfont.eot');
        src: url('../fonts/GandhiSans-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/GandhiSans-Regular-webfont.woff') format('woff'), url('../fonts/GandhiSans-Regular-webfont.ttf') format('truetype'), url('../fonts/GandhiSans-Regular-webfont.svg#GandhiSansRegular') format('svg');
        font-weight: normal;
        font-style: normal;
    }
    // ------------------------------------------------------------------
    //   Web font - Gandhi Sans Bold
    // ------------------------------------------------------------------
    @font-face {
        font-family: 'GandhiSansBold';
        src: url('../fonts/GandhiSans-Bold-webfont.eot');
        src: url('../fonts/GandhiSans-Bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/GandhiSans-Bold-webfont.woff') format('woff'), url('../fonts/GandhiSans-Bold-webfont.ttf') format('truetype'), url('../fonts/GandhiSans-Bold-webfont.svg#GandhiSansBold') format('svg');
        font-weight: normal;
        font-style: normal;
    }
}