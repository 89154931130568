@import "variables/button";
@import "variables/colour";
@import "variables/layout";
@import "functions/colour";
@import "functions/units";
@import "mixins/button";
@import "mixins/layout";

.button {
    // ------------------------------------------------------------------
    //   Standard button
    // ------------------------------------------------------------------    
    @include button;

    // ------------------------------------------------------------------
    //   Button variants
    // ------------------------------------------------------------------
    @each $button-type, $button-style in $button-types{
        @if ($button-type != default){
            &--#{$button-type}{

                @include button($button-style...);
            }
        }
    }

    &--disabled{
        @include button(
            $colour: $black80,
            $background-colour: $black20,
            $background-image: none,
            $box-shadow: none,

            $colour-hover: $black80,
            $background-colour-hover: $black20,
            $background-image-hover: none,
            $box-shadow-hover: none,
            $border-colour-hover: $grey80,

            $colour-active: $black80,
            $background-colour-active: $black20,
            $background-image-active: none,        
            $box-shadow-active: none,        
            $border-colour-active: $grey80
        );

        opacity: 0.5;
        cursor: not-allowed;
    }

    // ------------------------------------------------------------------
    //   Student OASIS login button
    // ------------------------------------------------------------------
    &--student-oasis {
        @include button(
            $colour: $white,
            $background-colour: shade($oasis-student, 20),
            $background-image: linear-gradient(to top, #006597 0%, #007bb5 50.76%, #0087c9 54.25%, #0d8dcd 100%),
            $border-colour: #006697,
            $box-shadow: (0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45)),
            $text-shadow: 0 -1px 0 rgba(0, 0, 0, .4),

            $colour-hover: $white,
            $background-colour-hover: shade($oasis-student, 10),
            $background-image-hover: linear-gradient(to top, #005983 0%, #006597 50.76%, #0073ab 54.25%, #0087c9 100%),
            $border-colour-hover: #ffbf00,
            $box-shadow-hover: (0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45)),

            $colour-active: $white,
            $background-colour-active: shade($oasis-student, 30),
            $background-image-active: linear-gradient(to top, #0087c9 0%, #0087c9 45.12%, #0087c9 49.19%, #006597 100%),
            $border-colour-active: #006697,
            $box-shadow-active: (0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45))
        );
    }

    // ------------------------------------------------------------------
    //   Staff OASIS login button
    // ------------------------------------------------------------------
    &--staff-oasis {
        @include button(
            $colour: $white,
            $background-colour: shade($oasis-staff, 20),
            $background-image: linear-gradient(to top, #003b53 0%, #004563 50.76%, #0d4f6b 54.25%, #195771 100%),
            $border-colour: #004563,
            $box-shadow: (0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .5)),
            $text-shadow: 0 -1px 0 rgba(0, 0, 0, 1),

            $colour-hover: $white,
            $background-colour-hover: shade($oasis-staff, 10),
            $background-image-hover: linear-gradient(to top, #003145 0%, #00374f 50.76%, #00415d 54.25%, #004563 100%),
            $border-colour-hover: #ffbf00,
            $box-shadow-hover: (0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .5)),
            
            $colour-active: $white,
            $background-colour-active: shade($oasis-staff, 30),
            $background-image-active: linear-gradient(to top, #004563 0%, #256179 45.12%, #256179 49.19%, #00374f 100%),
            $border-colour-active: #004563,
            $box-shadow-active: (0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45))
        );
    }

    &--full{
        width: 100%;
    }    
}

.with-icon {
    // Symbolset icon
    .ss-icon {
        @include mq(tablet-small) {
            display: block;
            float: left;
            width: 30px;
            margin-bottom: -4px; // Adjust the position
            margin-top: 1px;
            font-size: rem(21px);
        }
    }
    .button-icon {
        display: inline-block;
        float: left;
        background: {
            repeat: no-repeat;
            size: 100%;
        }
        width: $space-m;
        height: $space-m;
        margin-right: $space-s;
    }
    // Label
    span {
        @include mq(tablet-small) {
            display: block;
            padding-left: rem(30px);
        }
    }
}