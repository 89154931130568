@import "variables/colour";
@import "variables/colour-maps";
@import "mixins/colours";
// ------------------------------------------------------------------
//   Content link colour
//
//   Syntax:
//   a {
//     @include link-colour( $normal: #000, $border: false );
//   }
// ------------------------------------------------------------------
@mixin link-colour($normal: $link-colour, $hover: $link-colour-hover, $active: $link-colour-active, $visited: $link-colour-visited, $focus: $link-colour-focus, $border: true) {
    $link-colours: (
        normal: $normal,
        visited: $visited,
        focus: $focus,
        hover: $hover,
        active: $active,
    );
    $background-colours:(
        active: none,
        focus: none,
    );
    @include state-colours($link-colours, $background-colours);

    @if $border == true {
        @include link-underline-border((
            normal: tint($normal, 60),
            visited: tint($visited, 35),
            hover: tint($hover, 70),
            focus: tint($focus, 60),
            active: tint($active, 60)
        ));
    }
    @else{
        &,
        &:visited,
        &:hover,
        &:focus,
        &:active{
            border-bottom: none;
        }
    }

    text-decoration: none;
}

// ------------------------------------------------------------------
//   Content link colour high contrast
//
//   Syntax:
//   a {
//     @include link-colour--high-contrast( $normal: #fff, $border: false );
//   }
// ------------------------------------------------------------------
@mixin link-colour--high-contrast($normal: $link-colour--high-contrast, $hover: $link-colour-hover--high-contrast, $active: $link-colour-active--high-contrast, $visited: $link-colour-visited--high-contrast, $focus: $link-colour-focus--high-contrast, $border: true) {
    $link-colours: (
        normal: $normal,
        visited: $visited,
        focus: $focus,
        hover: $hover,
        active: $active,
    );

    $background-colours:(
        focus: $primary-colour,
    );
    
    @include state-colours($link-colours, $background-colours);

    @if $border == true {
        @include link-underline-border((
            normal: tint($normal, 60),
            visited: tint($visited, 35),
            hover: tint($hover, 70),
            focus: tint($focus, 60),
            active: tint($active, 60)
        ));
    }
    @else{
        &,
        &:visited,
        &:hover,
        &:focus,
        &:active{
            border-bottom: none;
        }
    }

    text-decoration: none;
}

// ------------------------------------------------------------------
//   List link colour
//
//   Syntax:
//   @include list-link-colour;
//   @include list-link-colour(#fff);
// ------------------------------------------------------------------
@mixin list-link-colour($background: false) {
    @include state-colours($list-link-colours, $list-link-background-colours, $background);

    text-decoration: none;
}


// ------------------------------------------------------------------
//   List link colour high contrast
//
//   Syntax:
//   @include list-link-colour--high-contrast;
//   @include list-link-colour--high-contrast(#fff);
// ------------------------------------------------------------------
@mixin list-link-colour--high-contrast($background: null) {
    $background-colours: (
        normal: if($background, $background, $list-link-background-colour--high-contrast),
        hover: $list-link-background-colour-hover--high-contrast,
        active: $list-link-background-colour-active--high-contrast,
        visited: if($background, $background, $list-link-background-colour-visited--high-contrast),
        focus: $list-link-background-colour-focus--high-contrast,
    );

    @include state-colours($list-link-colours--high-contrast, $background-colours, $default-background: true);
    
    text-decoration: none;
}

@mixin link-underline-border($state-border-colours){
    border-bottom: 1px solid map-get($state-border-colours, normal);
    
    @each $state in (visited, focus, hover, active){
        &:#{$state}{
            border-bottom: 1px solid map-get($state-border-colours, $state);
        }
    }
}