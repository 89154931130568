@import "variables/layout";
@import "mixins/layout";

// ------------------------------------------------------------------
//   Search form
// ------------------------------------------------------------------
.form-search {
    position: relative;
    input[type="search"] {
        position: relative;
        float: left;
        width: 70%;
        font-size: $space-m;
        @include mq(tablet-port) {
            width: 75%;
        }
        @include mq(tablet-land) {
            width: 73%;
        }
        @include mq(wide-screen) {
            width: 70%;
        }
    }
    .search-button {
        width: 30%;
        @include mq(tablet-port) {
            width: 25%;
        }
        @include mq(tablet-land) {
            width: 27%;
        }
        @include mq(wide-screen) {
            width: 30%;
        }
    }
    .form-group,
    fieldset {
        margin-bottom: 0;
    }
}