@import "variables/layout";
@import "mixins/layout";

.form-group{
    &--full{
        @extend .form-group;
    }
    
    &--inline{
        @extend .form-group--inline-3;
    }
}

%form-group--inline{
    @extend .form-group;
    width: 100%;
}

@for $i from 1 through ($total-columns - 1) {
    .form-group--inline-#{$i}{
        @extend %form-group--inline;

        @include mq(tablet-small){
            > label:first-child,
            .form-group__label{
                @include span-columns($i);
            }
        }
    }
}