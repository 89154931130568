@import "variables/layout";
@import "functions/layout";

// ------------------------------------------------------------------
//   Responsive breakpoints
//
//   $media variable: tablet-small, tablet-port, tablet-land, wide-screen
//
//   Syntax:
//   @include mq(tablet-small) {
//     Add your styles
//   }
//   @include mq($max:tablet-land) {
//     Add your styles
//   }
//   @include mq(tablet-small, wide-screen) {
//     Add your styles
//   }
// ------------------------------------------------------------------
@mixin mq($min: null, $max: null) {
    $min-width: null;
    $max-width: null;
    @each $break-point-name, $break-point-width in $break-points {
        @if ($min==$break-point-name) {
            $min-width: $break-point-width;
        }
        @if ($max==$break-point-name) {
            $max-width: ($break-point-width - 1);
        }
    }
    @if ($min-width and $max-width) {
        @media only screen and (min-width: $min-width) and (max-width: $max-width) {
            @content;
        }
    }
    @else if ($min-width) {
        @media only screen and (min-width: $min-width) {
            @content;
        }
    }
    @else if ($max-width) {
        @media only screen and (max-width: $max-width) {
            @content;
        }
    }
}

@mixin span-columns( $columns, $total-columns: $total-columns, $column-width: $column-width, $gutter-width: $gutter-width) {
    width: columns($columns, $total-columns, $column-width, $gutter-width);
    float: left;
    margin-right: gutter($total-columns, $column-width, $gutter-width);
}

@mixin block-grid-item($per-row, $spacing: 4%, $small-per-row: ceil($per-row/2)){
    float: left;
    width: block-grid-item-width($per-row, $spacing);
    margin: 0 $spacing ($spacing/2) 0;

    &:nth-child(#{$per-row}n){
        margin-right: 0;
    }

    @include mq($max: tablet-small){
        width: block-grid-item-width($small-per-row, $spacing);

        &:nth-child(#{$per-row}n){
            margin-right: $spacing;
        }

        &:nth-child(#{$small-per-row}n){
            margin-right: 0;
        }            
    }
}

@mixin pre( $columns, $total-columns: $total-columns, $column-width: $column-width, $gutter-width: $gutter-width) {
    margin-left: space($columns, $total-columns, $column-width, $gutter-width);
}

@mixin post( $columns, $total-columns: $total-columns, $column-width: $column-width, $gutter-width: $gutter-width) {
    margin-right: space($columns, $total-columns, $column-width, $gutter-width);
}

@mixin prefix( $columns, $total-columns: $total-columns, $column-width: $column-width, $gutter-width: $gutter-width) {
    padding-left: space($columns, $total-columns, $column-width, $gutter-width);
}

@mixin suffix( $columns, $total-columns: $total-columns, $column-width: $column-width, $gutter-width: $gutter-width) {
    padding-right: space($columns, $total-columns, $column-width, $gutter-width);
}

@mixin container() {
    @include apply-container
}

@mixin apply-container( $columns: $total-columns) {
    &:after {
        content: " ";
        display: table;
        clear: both;
    }
    max-width: rem($container-width - ($grid-padding*2));
    padding-left: 0;
    padding-right: 0;
    margin: {
        left: auto;
        right: auto;
    }
}

@mixin last{
    margin-right: 0 !important;
}

// ------------------------------------------------------------------
//   Apply clearfix
//
//   Based on micro clearfix hack by Nicolas Gallagher.
//   See: http://nicolasgallagher.com/micro-clearfix-hack/
//
//   Syntax:
//   @include clearfix;
//
// ------------------------------------------------------------------
@mixin clearfix{
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// ------------------------------------------------------------------
//   Column spans
//
//   Spread an element over I columns of a N column layout
//
//   Syntax:
//   @include columns(3);
//   @include columns(3, 9);
//
// ------------------------------------------------------------------
@mixin columns($i, $total-columns: $total-columns) {
    @include container;
    @include mq(tablet-land) {
        @include span-columns($i, $total-columns);
    }
}