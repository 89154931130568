@import "variables/button";
@import "variables/colour";
@import "variables/layout";
@import "functions/colour";
@import "functions/units";
@import "mixins/border";
@import "mixins/input";
@import "mixins/layout";

// ------------------------------------------------------------------
//   Variables
// ------------------------------------------------------------------

$dropdown-colour: $button-colour !default;
$dropdown-colour-hover: $button-colour-hover !default;
$dropdown-colour-active: $button-colour-active !default;


// ------------------------------------------------------------------
//   Dropdown menu
// ------------------------------------------------------------------
.dropdown {
    position: relative;
    .dropdown__button {
        @include action-input(
            $colour: $dropdown-colour,
            $colour-hover: $dropdown-colour-hover,
            $colour-active: $dropdown-colour-active
        );
        position: relative;
        margin-bottom: 0;
        padding: 0;
        text-align: left;
        overflow: hidden;
        &.is-expanded {
            @include colours(
                $colour: $button-colour--dark,
                $background-colour: $button-background-colour--dark,
                $background-image: $button-background-image--dark,
                $border-colour: $button-border-colour--dark,
                $box-shadow: $button-box-shadow--dark,
                $text-shadow: $button-text-shadow--dark,

                $colour-hover: $button-colour-hover--dark,
                $background-colour-hover: $button-background-colour-hover--dark,
                $background-image-hover: $button-background-image-hover--dark,
                $border-colour-hover: $button-border-colour-hover--dark,
                $box-shadow-hover: $button-box-shadow-hover--dark,

                $colour-active: $button-colour-active--dark,
                $background-colour-active: $button-background-colour-active--dark,
                $background-image-active: $button-background-image-active--dark,
                $border-colour-active: $button-border-colour-active--dark,
                $box-shadow-active: $button-box-shadow-active--dark                
            );
            .dropdown__button__toggle {
                box-shadow: -1px 0 0 0 rgba(255, 255, 255, 0.1); // drop shadow
            }
        }
    }
    // Toggle label
    .dropdown__button__label {
        padding: $space-s ($space-xx+$space-m) $space-s $space-m;
    }
    // Toggle icon
    .dropdown__button__toggle {
        float: right;
        margin-bottom: -100em; // Full height border trick
        border-left: 1px solid rgba(0, 0, 0, 0.1); // Divider
        padding-bottom: 100em; // Full height border trick
        width: rem(46px);
        box-shadow: -1px 0 0 0 rgba(255, 255, 255, 0.5); // drop shadow
        i {
            position: absolute;
            top: 50%;
            font-size: 14px;
            text-align: center;
            right: $space-m;
            margin-top: rem(-7px);
        }
    }
    // Dropdown menu
    .dropdown__menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 100;
        float: left;
        min-width: 10em;
        margin: 0;
        border: 1px solid $black;
        border-top: none;
        padding: 0;
        background-color: $black80;
        background-clip: padding-box; // prevents bg color from leaking outside the border
        box-shadow: $box-shadow-x $box-shadow-y $box-shadow-blur $box-shadow-colour;
        font-size: rem(14px);
    }
    .dropdown__menu> li> a {
        display: block;
        padding-top: rem($space-s/2); // smidgen smaller than standard linked list
        padding-bottom: rem($space-s/2); // smidgen smaller than standard linked list
    }
}