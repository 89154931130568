// Black opacity
@function black($opacity) {
    @return rgba(0, 0, 0, $opacity)
}

// White opacity
@function white($opacity) {
    @return rgba(255, 255, 255, $opacity)
}

// Tints (add percentage of white to a color)
@function tint($colour, $percent) {
    @return mix(white, $colour, $percent);
}

// Tones (add percentage of black to a color)
@function shade($colour, $percent) {
    @return mix(black, $colour, $percent);
}

@function contrasting-gray($colour) {
    @return invert(mix(grayscale($colour), hsl(0, 0%, 50%), 30%));
}

@function text-contrast($color, $threshold: 50) {
    @return if(lightness($color) < $threshold, $white, $black80);
}

@function watermark($colour, $percent: 15%) {
    @return if(lightness($colour) <= ($percent + 5%), lighten($colour, $percent), darken($colour, $percent));
}