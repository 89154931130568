@import "variables/border";
@import "variables/colour";
@import "variables/input";
@import "functions/units";
@import "mixins/icons";
@import "mixins/input";
@import "mixins/layout";
@import "elements/icon";


// ------------------------------------------------------------------
//   Includes support for all HTML5 types
// ------------------------------------------------------------------
#{$text-selector} {
    appearance: none; // Disable default appearance of input elements
}

#{$text-selector},
textarea {
    max-width: 100%;
    width: 100%;
    border-radius: $border-radius;
    @include mq(tablet-small) {
        width: auto;
    }
    box-shadow: inset -1px 1px 4px rgba(0, 0, 0, .16);
    @include placeholder-colour($black65); // Placeholder text
    &:focus {
        background-color: $white;
        border: 1px solid $blue;
    }
}

textarea {
    resize: none; // Disable textarea resize
}

// ------------------------------------------------------------------
// Search field-specific styling - magnifier icon
// ------------------------------------------------------------------
input[type="search"] {
    margin-bottom: 0;
    background: $white url('../img/icon-search.png') no-repeat $space-s 50%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: rem(40px);
    padding-left: rem(42px);
}

.content {
    #{$text-selector},
    textarea {
        &.form-error {
            &+i {
                @include icon(warning, $includeStyles: false);
                color: $red;
                right: rem(35px);
                top: rem(-5px);
                padding-left: $space-s;
            }
            &+.form-error-message {
                top: rem(-6px);
            }
        }
        &.form-valid {
            &+i {
                @include icon(tick, $includeStyles: false);
                color: $green;
                right: rem(35px);
                top: rem(-5px);
                padding-left: $space-s;
            }
        }
    }
}