@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/colour";
@import "functions/layout";
@import "functions/units";
@import "mixins/layout";
@import "components/module-title";

// ------------------------------------------------------------------
//   Footer accordions (mobile only)
// ------------------------------------------------------------------
.slide-toggle {
    margin-bottom: rhythm(1/4);
    .page-footer__content__title {
        @extend .module-title;
        display: none;
        margin-top: 0;
        margin-bottom: 1rem;
        padding-left: .84375rem;
        font-size: 1.17em;
        @include mq(tablet-land) {
            display: block;
        }
    }
    .button {
        border: 1px solid $grey80; // Overwrite link colour
        line-height: normal;
        text-decoration: none;
        text-transform: uppercase;
        vertical-align: middle;
        background-color: $black10;
        cursor: pointer;
        outline: none;
        -webkit-appearance: none; // Remove platform specific styles
        background-clip: padding-box; // Prevents bg colour from leaking outside the border
        background-image: linear-gradient(to top, $black10 0%, $grey15 45.12%, $grey10 49.19%, $white 100%);
        box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px $white;
        font-size: $button-font-size;
        user-select: none; // Text unselectable
        color: shade($grey, 45);
        display: block;
        width: 100%;
        margin-bottom: 0;
        text-align: left;
        font-family: $header-font-family;
        padding: 0.75rem $space-m;
        border-radius: $border-radius;
        font-size: rem(16px);
        @include mq(tablet-land) {
            display: none;
        }
        &.is-expanded {
            border-color: $yellow; // Overwrite the existing border colour
            border-bottom-color: $grey80;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            @include letterpress-white(0.5);
        }
        // Toggle icon
        i {
            float: right;
            color: $purple;
            padding-top: 0;
            font-size: rem(20px);
        }
        &:hover,
        &:focus {
            // Hover and focus status
            border-color: $yellow;
            background-color: $black5; // Solid background colour
            color: tint($purple, 20);
            box-shadow: inset 0 0 0 1px $white;
            background-image: linear-gradient(to top, $black10 0%, $grey15 45.12%, $grey10 49.19%, $white 100%);
        }
        &:active {
            // Active status
            border-color: $grey80;
            background-color: $black15; // Fallback colour
            color: $purple; // Overwrite link style
            box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px $white;
            background-image: linear-gradient(to top, $grey15 0%, $grey10 41.5%, $grey5 54.88%, $grey30 100%);
        }
        &:disabled {
            // Disabled status
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
    .dropdown__button {
        display: block; // Force to display
        padding-top: 0;
        padding-bottom: 0;
        border-radius: $border-radius;
        &.is-expanded {
            border-color: $black;
            .dropdown__button__label,
            i {
                color: $white;
                text-shadow: 0 -1px 0 rgba(0, 0, 0, 1);
            }
            &:hover {
                .dropdown__button__label,
                i {
                    color: $yellow;
                }
            }
        }
    }
    // Remove margin botton on the last block
    &:last-of-type {
        margin-bottom: 0;
    }
    &__content {
        display: none;
        border: 1px solid $yellow;
        border-top: none;
        background: $white;
        font-size: rem(14px);
        box-shadow: inset $inner-box-shadow-colour $inner-box-shadow-x $inner-box-shadow-y $inner-box-shadow-blur;
        p {
            margin-top: 0;
            font-size: rem(14px);
        }
        &.boxed {
            padding: 1em;
            @include mq(tablet-land) {
                padding: 0;
            }
        }
        @include mq(tablet-land) {
            border: none;
            background: none;
            box-shadow: none;
        }
    }
}