@import "functions/colour";
// ------------------------------------------------------------------
//   Curtin Brand Colours
// ------------------------------------------------------------------

// Primary
$gold:              #cc9900 !default;

// Faculty
$faculty-sci-eng:   #004b8b !default;
$faculty-cas:       #af1800 !default;
$faculty-hum:       #9b0664 !default;
$faculty-hea-sci:   #663366 !default;
$faculty-cbs:       #336325 !default;


// Neutrals
$curtin-tan:        #f1e4c8 !default;
$curtin-sand:       #f5edd0 !default;
$curtin-cream:      #fff5d1 !default;
$curtin-ecru:       #faf6e8 !default;
$curtin-mint:       #edf0de !default;

// Oasis
$oasis-student:    #0088ca !default;
$oasis-staff:      #195872 !default;


// ------------------------------------------------------------------
//   Well Defined Colours
// ------------------------------------------------------------------

$black:             #000000 !default;
$grey:              #999999 !default;
$aqua:              #008397 !default;
$red:               #95000c !default;
$orange:            #cc4600 !default;
$purple:            #3f0077 !default;
$green:             #178800 !default;
$yellow:            #ffbf00 !default;
$blue:              #1473Ab !default;
$dark-blue:         #004663 !default;
$brick:             #b26b65 !default;
$pink:              #c30064 !default;
$olive:             #687724 !default;
$brown:             #4d3423 !default;


// ------------------------------------------------------------------
//   Shades
// ------------------------------------------------------------------

// Greys
$grey-darker:      #383838 !default;
$grey-dark:        #414141 !default;
$grey-light:       #f5f5f5 !default;
$grey-lighter:     #fafafa !default;
$white:            #ffffff !default;

$grey5:            tint($grey, 95) !default;
$grey10:           tint($grey, 90) !default;
$grey15:           tint($grey, 85) !default;
$grey20:           tint($grey, 80) !default;
$grey25:           tint($grey, 75) !default;
$grey30:           tint($grey, 70) !default;
$grey35:           tint($grey, 65) !default;
$grey40:           tint($grey, 60) !default;
$grey45:           tint($grey, 55) !default;
$grey50:           tint($grey, 50) !default;
$grey55:           tint($grey, 45) !default;
$grey60:           tint($grey, 40) !default;
$grey65:           tint($grey, 35) !default;
$grey70:           tint($grey, 30) !default;
$grey75:           tint($grey, 25) !default;
$grey80:           tint($grey, 20) !default;
$grey85:           tint($grey, 15) !default;
$grey90:           tint($grey, 10) !default;
$grey95:           tint($grey, 5) !default;

$black5:           tint($black, 95) !default;
$black10:          tint($black, 90) !default;
$black15:          tint($black, 85) !default;
$black20:          tint($black, 80) !default;
$black25:          tint($black, 75) !default;
$black30:          tint($black, 70) !default;
$black35:          tint($black, 65) !default;
$black40:          tint($black, 60) !default;
$black45:          tint($black, 55) !default;
$black50:          tint($black, 50) !default;
$black55:          tint($black, 45) !default;
$black60:          tint($black, 40) !default;
$black65:          tint($black, 35) !default;
$black70:          tint($black, 30) !default;
$black75:          tint($black, 25) !default;
$black80:          tint($black, 20) !default;
$black85:          tint($black, 15) !default;
$black90:          tint($black, 10) !default;
$black95:          tint($black, 5) !default;

//Secondary yellow tints
$yellow5:           tint($yellow, 95) !default;
$yellow10:          tint($yellow, 90) !default;
$yellow15:          tint($yellow, 85) !default;
$yellow20:          tint($yellow, 80) !default;
$yellow25:          tint($yellow, 75) !default;
$yellow30:          tint($yellow, 70) !default;
$yellow35:          tint($yellow, 65) !default;
$yellow40:          tint($yellow, 50) !default;
$yellow45:          tint($yellow, 55) !default;
$yellow50:          tint($yellow, 50) !default;
$yellow55:          tint($yellow, 45) !default;
$yellow60:          tint($yellow, 40) !default;
$yellow65:          tint($yellow, 35) !default;
$yellow70:          tint($yellow, 30) !default;
$yellow75:          tint($yellow, 25) !default;
$yellow80:          tint($yellow, 20) !default;
$yellow85:          tint($yellow, 15) !default;
$yellow90:          tint($yellow, 10) !default;
$yellow95:          tint($yellow, 5)  !default;


// ------------------------------------------------------------------
//   Social Media Brand Colours
// ------------------------------------------------------------------

$social-facebook:   #3b5998 !default;
$social-twitter:    #00aced !default;
$social-googleplus: #dd4b39 !default;
$social-youtube:    #bb0000 !default;
$social-linkedin:   #007bb6 !default;
$social-instagram:  #517fa4 !default;
$social-rss:        #cc4600 !default;
$social-pinterest:  #cb2027 !default;
$social-weibo:      #e6162d !default;
$social-tumblr:     #35465c !default;
$social-email:      shade($yellow, 5) !default;