// ------------------------------------------------------------------
//   Content section on home page
// ------------------------------------------------------------------
@at-root {
    .page-home .content {
        padding: $space-x 0;
    }
}

// ------------------------------------------------------------------
//   Content section
// ------------------------------------------------------------------
.content {
    @include clearfix;
}

// ------------------------------------------------------------------
//   Level 1 Section Overview Page
// ------------------------------------------------------------------
.page-section {
    .content {
        padding-bottom: $space-l;
        @include mq(tablet-land) {
            padding-bottom: $space-xx;
        }
    }
    .panel--two-columns {
        &:first-child {
            padding-left: 0;
            border-left: 0;
        }
        &:last-child {
            padding-right: 0;
            border-right: 0;
        }
    }
}