@import "variables/layout";
@import "variables/form";
@import "functions/units";
@import "mixins/icons";
@import "mixins/text";
@import "elements/icon";
@import "templates/form-validation";

@mixin form-validation(
    $message-colour,
    $message-border,
    $message-background-colour,
    $inline-colour,
    $inline-border,
    $inline-background-colour,
    $highlight-colour,
    $highlight-border,
    $highlight-background-colour,
    $field-colour,
    $field-border,
    $field-background-colour,    
    $icon-colour,    
    $marker-border,
    $icon,
    $form-validation-template-prefix: $form-validation-template-prefix,
    //These two parameters are unused by included so a message style map
    //can be passed through as a spread parameter
    $panel-header-colour: null, 
    $panel-header-background-colour: null
){
    // ------------------------------------------------------------------
    // Validation icon
    // ------------------------------------------------------------------
    &--icon {
        @extend #{$form-validation-template-prefix}--icon;
        @include icon($icon, $position: null);
        color: $icon-colour;
    }

    // ------------------------------------------------------------------
    // Validation message global
    // ------------------------------------------------------------------
    &--message--page {
        @extend #{$form-validation-template-prefix}--message--page;
        
        border: $message-border;
        background-color: $message-background-colour;
        color: $message-colour;
        @include icon($icon);
        &:before {
            color: $icon-colour;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6{
            color: $message-colour;
        }

        &--first {
            margin-bottom: $space-m;
            p {
                font-weight: $bold;
                font-size: rem(18px);
            }
        }                
    }

    // ------------------------------------------------------------------
    // Validation message inline
    // ------------------------------------------------------------------
    &--message--inline {
        @extend #{$form-validation-template-prefix}--message--inline;
        color: $inline-colour;
        background-color: $inline-background-colour;
        border: $inline-border;
        @include icon($icon);
        
        &:before {
            color: $icon-colour;
        }
    }

    &--highlight {
        @extend #{$form-validation-template-prefix}--highlight;
        border: $highlight-border;
        border-left: $marker-border;
        color: $highlight-colour;
        background-color: $highlight-background-colour;
        input,
        textarea,
        select {
            border: $field-border !important;
            background-color: $field-background-colour !important;
            color: $field-colour !important;
        }
    }
}