@import "variables/border";
@import "variables/colour";
@import "variables/input";
@import "variables/font";
@import "variables/layout";
@import "functions/units";
@import "mixins/input";
@import "mixins/layout";

// ------------------------------------------------------------------
// Standard form
// ------------------------------------------------------------------
form {
    // Fieldset
    fieldset {
        border: none;
        padding: 0;
        margin-left: 0;
        margin-right: 0;
        min-width: 100%;
        margin-top: $space-l;
    }
    // Legend
    legend {
        font-family: $base-font-family;
        text-transform: none;
        color: $black75;
        margin-bottom: .8em;
        border-bottom: 1px solid $black20;
        display: block;
        width: 100%;
        font-size: rem(20px);
    }
}

//Label style
.form-group label,
fieldset label,
.form-sub-head {
    display: block;
    margin: 0;
    font-weight: bold;
    font-size: rem(16px);
}

.form-sub-head {
    margin-bottom: rem(7px);
}

// ------------------------------------------------------------------
//   General form
// ------------------------------------------------------------------
fieldset,
.form-group {
    @include clearfix;
    margin-bottom: $space-m;
}

// Abbreviation style
form abbr[title] {
    border-bottom: none;
    text-decoration: none;
}

.form-actions{
    .button--primary-submit,
    .button--primary,
    .primary-action{
        float: right;
        margin-left: $space-xs;            
    }
}

// ------------------------------------------------------------------
//   Fieldset group
// ------------------------------------------------------------------
.fieldset-group {
    display: block;
    float: left;
    width: 100%;
    margin: rem(8px 0 0 0);
}


// ------------------------------------------------------------------
//   Required field
// ------------------------------------------------------------------
.req {
    line-height: 0;
    font-size: rem(19px);
}

// ------------------------------------------------------------------
//   Optional field
// ------------------------------------------------------------------
.optional {
    font-size: rem(13px);
}


// ------------------------------------------------------------------
// Valid message (inline and instant indicator)
// ------------------------------------------------------------------
.form-valid {
    &+.form-success-message {
        display: inline-block;
        margin-left: $space-s;
    }
}

.form-input-invalid {
    border: 1px solid $red;
    color: $red;
    background-color: $curtin-ecru;
}

.form-input-invalid:focus {
    border: 1px solid $orange;
    color: #595959;
    background-color: $white;
}

.form-success {
    background-color: $olive;
    color: $white;
    display: block;
    clear: both;
}

.form-feedback-content {
    margin: rem(5px $space-s);
}

.form-autocomplete {
    position: absolute;
    z-index: 1;
    width: 100%;
    border: 1px solid $box-border-colour;
    background-color: $white;
    box-shadow: $box-shadow-x $box-shadow-y $box-shadow-blur $box-shadow-colour;
    font-size: rem(16px);
}

.form-autocomplete__title {
    margin: 0;
    background: $grey10;
    border-top: 1px solid $border-colour;
    padding: $space-xs $space-m;
    font-size: rem(17px);
    &:first-of-type {
        border-top: none;
    }
}

.form-autocomplete__group {
    border-top: 1px solid $border-colour;
    margin: 0 0 $space-s 0;
    &:last-of-type {
        margin-bottom: 0;
    }
    li {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: rem(12px);
        @include mq(tablet-land) {
            font-size: rem(14px);
        }
        a {
            display: block;
            margin: 0;
            border: none;
            padding: rem(2px $space-m);
            &:hover {
                background: $yellow;
            }
        }
    }
}

.form-highlight {
    display: inline;
    background: tint($yellow, 50);
    font-weight: $bold;
}

input:disabled {
    &+label {
        opacity: 0.5;
        &:hover {
            cursor: default;
        }
    }
    .checkbox-stacked &,
    .radio-stacked & {
        &:hover {
            background: none !important;
        }
    }
}

// ------------------------------------------------------------------
//   Placeholder text - invalid
// ------------------------------------------------------------------
.form-input-invalid {
    @include placeholder-colour($red);
}