@import "variables/colour";
@import "variables/layout";
@import "mixins/layout";
@import "mixins/lists";

.inline-list {
    @include clearfix;
    @include no-bullets;
    @include mq(tablet-small) {
        li {
            display: inline-block;
            border-left: 1px solid $border-colour;
            &:first-child a {
                margin-left: 0;
            }
            &:first-child {
                border-left: none;
            }
        }
        a {
            border: none;
            margin-left: $space-m;
            margin-right: $space-m;
        }
    }
}