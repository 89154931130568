@import "variables/border";
@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/units";
@import "mixins/input";
@import "mixins/layout";
@import "mixins/text";

.tooltip {
    @include action-input(
        $colour: $button-colour--primary,
        $background-colour: $button-background-colour--primary,
        $background-image: $button-background-image--primary,
        $border-colour: $button-border-colour--primary,
        $box-shadow: $button-box-shadow--primary,
        $text-shadow: $button-text-shadow--primary,

        $colour-hover: $button-colour-hover--primary,
        $background-colour-hover: $button-background-colour-hover--primary,
        $background-image-hover: $button-background-image-hover--primary,
        $border-colour-hover: $button-border-colour-hover--primary,
        $box-shadow-hover: $button-box-shadow-hover--primary,

        $colour-active: $button-colour-active--primary,
        $background-colour-active: $button-background-colour-active--primary,
        $background-image-active: $button-background-image-active--primary,
        $border-colour-active: $button-border-colour-active--primary,
        $box-shadow-active: $button-box-shadow-active--primary
    );

    z-index: 1000;
    text-align: center;
    font-weight: $medium;
    cursor: help;
    width: rem(22px);
    height: rem(22px);
    border-radius: 50%;
    padding: rem(1px);
    margin: 0 0 0 $space-xs;
    @include mq(tablet-land) {
        width: rem(18px);
        height: rem(18px);
        font-size: rem(12px);
    }
    &:hover,
    &:focus,
    &.open {
        span,
        .tooltip__content {
            visibility: visible;
            opacity: 1;
            transition: opacity $transition-speed $transition-style, visibility 0s linear 0s;
        }
    }
    span,
    .tooltip__content {
        position: absolute;
        z-index: 1000;
        display: block;
        background: $grey10;
        border: 1px solid $border-colour;
        visibility: hidden;
        z-index: 1;
        text-shadow: none;
        font-family: $base-font-family;
        color: $black80;
        text-transform: none;
        text-align: left;
        font-size: rem(14px);
        @include adjust-leading-to(1);
        padding: $space-s;
        margin: 0 $space-s $space-s $space-m;
        box-shadow: $box-shadow-x $box-shadow-y $box-shadow-blur $box-shadow-colour;
        transition: opacity $transition-speed $transition-style 500ms, visibility 0s linear ($transition-speed+500ms);
        @include mq(tablet-land) {
            font-size: rem(12px);
        }
    }
}

#tooltip-box,
.tooltip-box {
    text-align: center;
    color: $white;
    background: $black;
    border-top: 1px solid $white;
    position: absolute;
    z-index: 100;
    font-size: rem(14px);
    padding: $space-s;
    &:after {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $black;
        content: '';
        position: absolute;
        left: 50%;
        bottom: -10px;
        margin-left: -10px;
    }
    &.top:after {
        border-top-color: transparent;
        border-bottom: 10px solid $black;
        top: -20px;
        bottom: auto;
    }
    &.left:after {
        left: 10px;
        margin: 0;
    }
    &.right:after {
        right: 10px;
        left: auto;
        margin: 0;
    }
}