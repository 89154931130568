@import "variables/border";
@import "variables/colour";
@import "variables/input";

// ------------------------------------------------------------------
//   File uploader
// ------------------------------------------------------------------
.file-upload {
    display: inline-block;
    border: 1px solid $input-border-colour;
    padding: 0.625rem;
    border-radius: $border-radius;
    background-color: $white;
    font-family: Helvetica, Arial, sans-serif;
    line-height: normal !important;
    vertical-align: middle;
    box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, .2);
}